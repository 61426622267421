import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {makeStyles} from "@mui/styles";
import useLocale from "../../hooks/useLocale";
import {BookIcon, MicIcon, QuizIcon, RewardIcon, VideoIcon} from "./svgIcons";

const useStyles = () => makeStyles({
    progressBarAfterSomeProgress: {
        position: "fixed",
        flexDirection: "row",
        top: "120px",
        height: "80px",
        display: "flex",
        marginTop: "50px",
        width: "100%",
        zIndex: "4",
        backgroundColor: "white",
        paddingTop: "15px",
        justifyContent: "center",
        "@media (min-width: 768px)": {
            height: "100px",
            paddingTop: "20px",
            left: 0
        }
    },
    videoImage: {
        height: "19px",
        width: "22px",
        margin: "5px",
        "@media (min-width: 768px)": {
            width: "38px",
            height: "32px",
            margin: "12px",
        },
    },
    bookImage: {
        height: "18px",
        width: "23px",
        margin: "5px",
        "@media (min-width: 768px)": {
            width: "40px",
            height: "30px",
            margin: "13px",
            marginTop: "15px",
        },
    },
    quizImage: {
        height: "22px",
        width: "15px",
        margin: "6px",
        marginTop: "5px",
        "@media (min-width: 768px)": {
            width: "24px",
            height: "37px",
            margin: "15px",
            marginRight: "15px",
        },
    },
    micImage: {
        height: "25px",
        width: "17px",
        margin: "6px",
        "@media (min-width: 768px)": {
            width: "25px",
            height: "35px",
            margin: "15px",
            marginRight: "15px",
        },
    },
    rewardImage: {
        height: "28px",
        width: "22px",
        margin: "5px",
        marginTop: "3px",
        "@media (min-width: 768px)": {
            width: "40px",
            height: "30px",
            margin: "13px",
        },
    },
    imageWrapper: {
        backgroundColor: "rgba(255, 255, 255, 1)",
        border: "2px solid rgba(98, 195, 238, 1)",
        borderRadius: "39px",
        width: "39px",
        height: "39px",
        display: "flex",
        flexDirection: "row",
        "@media (min-width: 768px)": {
            width: "68px",
            height: "68px"
        },
    },
    blueImageWrapper: {
        backgroundColor: "rgba(98, 195, 238, 1)",
        border: "2px solid rgba(98, 195, 238, 1)",
        borderRadius: "39px",
        width: "39px",
        height: "39px",
        display: "flex",
        flexDirection: "row",
        "@media (min-width: 768px)": {
            width: "68px",
            height: "68px"
        },
    },
    progressPoint: {
        display: "flex",
    },
    dividerWithProgress: {
        border: "1px solid rgba(98, 195, 238, 1)",
        width: "35px",
        "@media (min-width: 768px)": {
            width: "85px",
            marginTop: "32px"
        },
    },
    dividerWithNoProgress: {
        border: "1px dashed rgba(204, 204, 204, 1)",
        width: "35px",
        "@media (min-width: 768px)": {
            width: "85px",
            marginTop: "32px"
        },
    },
    video: {
        width: "329px",
        height: "200px",
        backgroundColor: "rgba(220, 222, 230, 1)",
        marginTop: "30px",
        marginLeft: "20px",
        marginRight: "30px",
    }
});


function ProgressBarGuidedReading(props) {
    const {progressState} = props;
    const {dir} = useLocale();
    const classes = useStyles(dir)();

    useEffect(() => {
    }, [progressState])

    function scrollToElementAndUpdateState(elementId) {
        return () => {
            document.getElementById(elementId).scrollIntoView(false);
        };
    }

    function getColourOfIcon(stateOfIcon) {
        return progressState === stateOfIcon
            ? "rgba(255, 255, 255, 1)"
            : progressState > stateOfIcon ? "rgba(98, 195, 238, 1)" : "rgba(207, 207, 207, 1)"
    }

    function getImageWrapper(iconState) {
        return progressState === iconState ? classes.blueImageWrapper : classes.imageWrapper;
    }

    function getDividerStyle(iconState) {
        return progressState > iconState ? classes.dividerWithProgress : classes.dividerWithNoProgress;
    }

    return (
        <div className={classes.progressBarAfterSomeProgress}>
            <div className={classes.progressPoint}>
                <div className={getImageWrapper(1)}
                     onClick={scrollToElementAndUpdateState("video")}>
                    <div className={classes.videoImage}>
                        <VideoIcon sx={{
                            fill: getColourOfIcon(1), fontSize: {md: "2.25rem", "xs": "1.5rem"}
                        }}/></div>
                </div>
                <hr className={getDividerStyle(1)}/>
            </div>
            <div className={classes.progressPoint}>
                <div className={getImageWrapper(2)}
                     onClick={scrollToElementAndUpdateState("book")}>
                    <div className={classes.bookImage}><BookIcon sx={{
                        fill: getColourOfIcon(2), fontSize: {md: "2.25rem", "xs": "1.5rem"}
                    }}/></div>
                </div>
                <hr className={getDividerStyle(2)}/>
            </div>
            <div className={classes.progressPoint}>
                <div className={getImageWrapper(3)}
                     onClick={scrollToElementAndUpdateState("quiz")}>
                    <div className={classes.quizImage}>
                        <QuizIcon sx={{
                            fill: getColourOfIcon(3), fontSize: {md: "2.25rem", "xs": "1.5rem"}
                        }}/></div>
                </div>
                <hr className={getDividerStyle(3)}/>
            </div>
            <div className={classes.progressPoint}>
                <div className={getImageWrapper(4)}
                     onClick={scrollToElementAndUpdateState("mic")}
                >
                    <div className={classes.micImage}>
                        <MicIcon sx={{
                            fill: getColourOfIcon(4), fontSize: {md: "2.25rem", "xs": "1.5rem"}
                        }}/></div>
                </div>
                <hr className={getDividerStyle(4)}/>
            </div>
            <div className={classes.progressPoint}>
                <div className={getImageWrapper(5)}
                     onClick={scrollToElementAndUpdateState("reward")}>
                    <div className={classes.rewardImage}>
                        <RewardIcon strokeColor={progressState === 5 ? "rgba(98, 195, 238, 1)" : "white"} sx={{
                            fill: getColourOfIcon(5), fontSize: {md: "2.25rem", "xs": "1.5rem"}
                        }}/></div>
                </div>
            </div>
        </div>
    )
}


ProgressBarGuidedReading.propTypes = {
    progressState: PropTypes.number
}

export default ProgressBarGuidedReading;