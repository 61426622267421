import React from "react";
import { useTranslation } from "react-i18next";
import VideoPlayer from "../../../../components/VideoPlayer";
import discoverAbjadiyatVideoPoster from "../../../../assets/images/discover_abjadiyat.png";

function DiscoverAbjadiyat() {
  const { t } = useTranslation();

  return (
    <div id="videoholder" className="basic-3">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h2 style={{ color: "rgba(60 180 229)" }}>
              {t("Discover Abjadiyat")}
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="image-container">
              <VideoPlayer
                currentVideo={
                  process.env.REACT_APP_STORAGE + "Abjadiyat_Video.mp4"
                }
                poster={discoverAbjadiyatVideoPoster}
              />
            </div>
            <p className="text-center">{t("Discover Abjadiyat Description")}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DiscoverAbjadiyat;
