import React, {useContext, useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import paymentImage from "../../assets/images/payments.png"
import SubscriptionPlan from "../SubscriptionPlan";
import {useTranslation} from "react-i18next";
import Loading from "../../components/Loading";
import {FireBaseContext} from "../../contexts/FireBase";
import {useLocation} from "react-router-dom";
import useClientDevice from "../../hooks/useClientDevice";
import SubscriptionDetails from "../SubscriptionDetails";

const useStyles = (dir) => makeStyles({
    container: {
        marginTop: "120px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        "@media (min-width: 768px)": {
            marginTop: "120px",
        },
    },
    heading: {
        alignItems: "center",
        textAlign: "center",
        fontSize: "21px",
        fontWeight: "bold",
        color: "rgba(105, 123, 140, 1)",
        lineHeight: "32px",
    },
    divider: {
        "@media (max-width: 768px)": {
            borderBottom: "1px solid rgba(209 209 209)",
        },
        "@media (min-width: 768px)": {
            borderRight: "1px solid rgba(209 209 209)",
            marginLeft: "27px",
            marginRight: "27px",
            marginTop: "6.4rem"
        },
        marginTop: "43px",
        marginRight: "23px",
        marginLeft: "23px"
    },
    plansHeading: {
        fontSize: "1.25rem",
        color: 'rgba(54, 180, 229, 1)',
        textAlign: "center",
        lineHeight: "25px",
        alignItems: "center",
        marginTop: "29px",
        "@media (min-width: 768px)": {
            marginTop: "67px",
            marginBottom: "8rem",
            color: "rgba(105, 123, 140, 1)"
        }
    },
    plans: {
        display: "flex",
        flexDirection: dir === "ltr" ? "row" : "row-reverse",
        justifyContent: "center",
        gap: "0.75rem",
        marginTop: "1.25rem",
        "@media (min-width: 768px)": {
            gap: "61px"
        }
    },
    loader: {
        marginTop: "5rem",
    },
    description: {
        "@media (min-width: 768px)": {
            width: "100%",
            marginTop: "10rem"
        }
    },
    subscriptionContainer: {
        "@media (min-width: 768px)": {
            display: "flex",
            flexDirection: "row",
            width: "100%",
        }
    },
    planDetails: {
        "@media (min-width: 768px)": {
            marginLeft: "61px",
            marginRight: "61px"
        }
    },
    paymentImage: {
        "@media (min-width: 768px)": {
            marginLeft: "8rem",
            marginRight: "8rem",
            marginTop: "3rem"
        }
    }
});


function Subscription() {
    const {t, i18n} = useTranslation();
    const currentDirection = i18n.dir();
    const classes = useStyles(currentDirection)();
    const [isSubscribing, setIsSubscribing] = useState(false);
    const {logEvent} = useContext(FireBaseContext);
    const search = useLocation().search;
    const encodedStudentId = new URLSearchParams(search).get('studentId') || undefined;
    const studentId = atob(encodedStudentId);
    const {isTablet, isMobile} = useClientDevice();

    useEffect(() => {
        logEvent("VisitSubscriptionPage", {STUDENT_ID: studentId});
        window.history.pushState({ prevUrl: window.location.href }, null);
    }, [])

    const propsOfAnnualPlan = {
        planName: t("Subscription Package 1"),
        priceOfPlan: t("Price of Annual plan"),
        totalAmountOfPlan: t("Total Price of Annual plan"),
        planType: "Yearly",
        planExplanation: t("Subscription Plan 1 Explanation"),
        eventNameOfPlan: "ClickOnOneTimePayment",
        offPercentage: t("off Percent for Annual plan"),
        originalPrice: "19 AED"
    };

    const propsOfMonthlyPlan = {
        planName: t("Subscription Package 2"),
        priceOfPlan: t("Price of Monthly plan"),
        planType: "Monthly",
        planExplanation: t("Subscription Plan 2 Explanation"),
        eventNameOfPlan: "ClickOnMonthlySubscription"
    }

    return (
        <div className={classes.container}>
            {isSubscribing ? <div className={classes.loader}><Loading/></div> :
                <div className={classes.subscriptionContainer}>
                    <div className={classes.description}>
                        <div className={classes.heading}>
                            <div>{t("Subscription Heading 1")}</div>
                            <div>{t("Subscription Heading 2")}</div>
                        </div>
                        {<SubscriptionDetails/>}
                    </div>
                    <div className={classes.divider}></div>
                    <div className={classes.planDetails}>
                        <div className={classes.plansHeading}>{t("Subscription Package Heading")}</div>
                        <div className={classes.plans}>
                            {<SubscriptionPlan setIsSubscribing={setIsSubscribing}
                                               plan={propsOfAnnualPlan}/>}
                            {<SubscriptionPlan plan={propsOfMonthlyPlan}
                                               setIsSubscribing={setIsSubscribing}/>}
                        </div>
                        {!(isMobile || isTablet) && <img
                            className={classes.paymentImage}
                            src={paymentImage}
                            alt="doodle image"
                        />}
                    </div>
                </div>}
        </div>
    );
}

export default Subscription;
