import React, {useRef, useState} from "react";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import ProgressBarGuidedReading from "../ProgressBarGuidedReading";
import GuidedReadingSectionDetails from "../GuidedReadingSectionDetails";
import BackgroundOfGuidedReading from "./BackgroundOfGuidedReading";
import HeadingOfGuidedReading from "./HeadingOfGuidedReading";
import FreeTrialForm from "../FreeTrialForm";
import Whatsapp from "../../layouts/public/Whatsapp";
import FreeTrial from "../../layouts/public/FreeTrial";

const useStyles = () => makeStyles({
    container: {
        direction: "rtl",
        display: "flex",
        overflow: "hidden",
        overflowY: "scroll",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        position: "relative",
        "@media (min-width: 768px)": {
            marginTop: "120px",
        },
    },
    pageDescription: {
        marginRight: "30px",
        marginLeft: "30px",
        fontWeight: "lighter",
        fontSize: "18px",
        marginTop: "100px",
        textAlign: "initial",
        lineHeight: "41px",
        fontStyle: "normal",
        color: "rgb(90,80,76)",
    },
    contentContainer: {
        marginTop: "150px",
        overflow: "hidden",
        overflowY: "scroll",
        "@media (min-width: 768px)": {
            width: "700px",
            marginTop: "40px",
        },
    },
    endSegment: {
        fontWeight: "bold",
        fontSize: "18px",
        marginTop: "30px",
        color: "rgba(77, 78, 76, 1)"
    },
    divider: {
        border: "1px dashed rgba(204, 204, 204, 1)",
        width: "80%",
        marginTop: "20px",
        "@media (min-width: 768px)": {
            marginTop: "32px"
        },
    },
    formHeading: {
        fontWeight: "bold",
        fontSize: "18px",
        marginTop: "16px",
        color: "rgba(98, 195, 238, 1)"
    }
});

function GuidedReading() {
    const {t} = useTranslation();
    const [progressState, setProgressState] = useState(0);
    const classes = useStyles(progressState)();
    const refOfPageDescription = useRef(null);

    return (
        <div className={classes.container}>
            <BackgroundOfGuidedReading/>
            <HeadingOfGuidedReading progressState={progressState}/>
            <ProgressBarGuidedReading progressState={progressState}/>
            <div className={classes.contentContainer}>
                {progressState >= 0 && <div className={classes.pageDescription}
                                            ref={refOfPageDescription}>{t("Guided Reading Description")}</div>}
                <GuidedReadingSectionDetails setProgressState={setProgressState}/>
            </div>
            <div className={classes.endSegment}>{t("Guided Reading End Segment")}</div>
            <hr className={classes.divider}></hr>
            <FreeTrialForm/>
            <Whatsapp/>
            <FreeTrial/>
        </div>
    );
}

export default GuidedReading;
