import { Checkbox } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import React, { useState } from "react";

const useStyles = makeStyles({
  root: {
    // color: "rgb(204 204 204)",
  },
});

function CheckboxInput({ name, onChange, defaultValue = false, ...reset }) {
  const [checked, setChecked] = useState(defaultValue);
  const classes = useStyles();
  const handleChange = (event) => {
    const { name, checked: value } = event.target;
    onChange({ target: { name, value } });
    setChecked(value);
  };
  return (
    <Checkbox
      name={name}
      checked={checked}
      onChange={handleChange}
      className={classes.root}
      {...reset}
    />
  );
}

CheckboxInput.propTypes = {
  name: PropTypes.string,
  reset: PropTypes.object,
  defaultValue: PropTypes.bool,
  onChange: PropTypes.func,
};

export default CheckboxInput;
