import React from "react";
import { useTranslation } from "react-i18next";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Typography } from "@mui/material";
const customStyles = {
  button: {
    cursor: "pointer",
    padding: "10px",
    width: "55px",
    height: "55px",
    boxShadow: "0 5px 4px 0 rgb(0 0 0 / 26%)",
    backgroundColor: "rgb(37 211 102)",
    animation: "pulse-orange 1.1s infinite",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@keyframes pulse-orange": {
      "0%": {
        transform: "scale(0.95)",
        boxShadow: "0 0 0 0 rgba(37 211 102/ 0.7)",
      },

      "70%": {
        transform: "scale(1)",
        boxShadow: "0 0 0 15px rgba(37 211 102/ 0)",
      },

      "100% ": {
        transform: "scale(0.95)",
        boxShadow: "0 0 0 5px rgba(37 211 102/ 0)",
      },
    },
  },
};
function Whatsapp() {
  const { i18n, t } = useTranslation();
  const clickHandler = () => {};
  const direction = i18n.dir();
  return (
    <a
      href={`https://api.whatsapp.com/send?phone=+971565064353&text=${t(
        "more details"
      )}`}
      style={{
        position: "fixed",
        zIndex: 99,
        bottom: "100px",
        right: "10px",
      }}
    >
      <Typography
        component="div"
        onClick={clickHandler}
        sx={{
          ...customStyles.button,
          borderRadius:
            direction === "rtl"
              ? " 55px 55px  55px 8px"
              : "55px  55px  8px 55px",
        }}
      >
        <WhatsAppIcon
          sx={{
            fontSize: "32px",
            color: "rgb(255, 255, 255)",
          }}
        />
      </Typography>
    </a>
  );
}

export default Whatsapp;
