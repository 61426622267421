import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Button from "../../../../components/Button";
import RegSuccess from "../../../../assets/images/reg_succes.svg";
import { DemoContext } from "../../../../contexts/Demo";
import { submitSalesForm } from "../../../../services/Demo";
import { useHistory } from "react-router";

const customStyles = {
  backBtn: {
    display: "flex",
    justifyContent: "center",
    "@media screen and  (max-width: 767px)": {
      justifyContent: "center",
    },
  },
};

const useStyles = makeStyles({
  message: {
    "& *": {
      fontSize: "18px",
      fontFamily: "Jannat !important",
    },
  },
});
function StepThreeRegister({ setActiveStep }) {
  const history = useHistory();
  const { t } = useTranslation();
  const { state, dispatch } = useContext(DemoContext);
  const { userInfo } = state;
  const newRegHandler = () => {
    dispatch({ type: "resetForm" });
    setActiveStep(-1);
    history.push("/");
  };

  useEffect(() => {
    submitUser();
  }, []);

  const submitUser = async () => {
    const baseUrl = `https://${
      process.env.REACT_APP_ENV === "prod"
        ? ""
        : process.env.REACT_APP_ENV + "."
    }abjadiyat.com`;
    const fields = {
      submittedAt: new Date().getTime(),
      fields: [
        {
          name: "firstname",
          value: userInfo.firstName,
        },
        {
          name: "lastname",
          value: userInfo.lastName,
        },
        {
          name: "school",
          value: userInfo.schoolName,
        },
        {
          name: "email",
          value: userInfo.email,
        },
        {
          name: "approve",
          value: `${baseUrl}/demo/approve/${encodeURIComponent(
            userInfo.accountToken
          )}`,
        },
        {
          name: "unapprove",
          value: `${baseUrl}/demo/disapprove/${encodeURIComponent(
            userInfo.accountToken
          )}`,
        },
        {
          name: "extend",
          value: `${baseUrl}/demo/extend/${encodeURIComponent(
            userInfo.accountToken
          )}`,
        },
        {
          name: "phone",
          value: userInfo.callingCode + userInfo.mobile,
        },
        {
          name: "account_type",
          value: "TRIAL",
        },
      ],
      context: {
        pageUri: baseUrl,
        pageName: "Abjadiyat Website",
      },
    };
    await submitSalesForm(fields);
  };

  const classes = useStyles();
  return (
    <Grid container rowSpacing={5} sx={{ pt: "30px", textAlign: "center" }}>
      <Grid item xs={12}>
        <img
          src={RegSuccess}
          alt="reg success"
          style={{ width: "268px", height: "212px" }}
        />
      </Grid>
      <Grid item xs={12} className={classes.message}>
        <p>{t("Account creation success")}</p>
        <p>{t("Trial email")}</p>
        <p
          style={{
            direction: "ltr",
            color: "rgb(98 195 238)",
            paddingTop: "5px",
          }}
        >
          {userInfo.email}
        </p>

        <p style={{ fontWeight: "bold" }}>
          {t("Trail account creation title")}
        </p>
      </Grid>
      <Grid item xs={12} sx={customStyles.backBtn}>
        <Button
          white
          label={t("Homepage")}
          styles={{ width: "149px" }}
          action={newRegHandler}
        />
      </Grid>
    </Grid>
  );
}

StepThreeRegister.propTypes = {
  setActiveStep: PropTypes.func.isRequired,
};

export default StepThreeRegister;
