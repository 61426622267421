import React, { useContext, useEffect, useState } from "react";
import rtlDoodle from "../../../../assets/images/rtl_doodle_grey.png";
import ltrDoodle from "../../../../assets/images/ltr_doodle_grey.png";
import rtlBlueCloud from "../../../../assets/images/rtl_blue_cloud.png";
import ltrBlueCloud from "../../../../assets/images/ltr_blue_cloud.png";
import { makeStyles } from "@mui/styles";
import HorizontalStepper from "./HorizontalStepper";
import StepOneRegister from "./StepOneRegister";
import StepTwoRegister from "./StepTwoRegister";
import StepThreeRegister from "./StepThreeRegister";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import VerifyEmail from "./VerifyEmail";
import { useHistory, useLocation, useParams } from "react-router";
import {
  approveTrial,
  postVerifyOTP,
  unapproveTrial,
} from "../../../../services/Demo";
import useToast from "../../../../hooks/useToast";
import { DemoContext } from "../../../../contexts/Demo";
import ExtendTrial from "./ExtendTrial";

const useStyles = (dir) =>
  makeStyles({
    homeHeader: {
      minHeight: "45rem",
      "@media screen and (max-width: 991px)": {
        paddingBttom: 0,
      },
    },
    doodle: {
      position: "absolute",
      top: "5rem",
      width: "20%",
      ...(dir === "ltr" ? { right: 0 } : { left: 0 }),
      "@media screen and (max-width: 991px)": {
        display: "none",
      },
    },
    blueCloud: {
      position: "absolute",
      width: "45%",
      ...(dir === "rtl" ? { right: 0 } : { left: 0 }),
      "@media screen and (max-width: 1330px)": {
        width: "60%",
      },
      "@media screen and (max-width: 991px)": {
        width: dir === "rtl" ? "30%" : "28%",
      },
      "@media screen and (max-width: 767px)": {
        display: "none",
      },
    },
    registrationForm: {
      width: "750px",
      zIndex: 1,
      boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.15)",
      background: "rgb(255 255 255)",
      borderRadius: "8px",
      padding: "2.5rem",
      position: "relative",
      border: "1px solid rgb(0 0 0 / 5%)",
      maxWidth: "90%",
      "@media screen and (max-width: 991px)": {
        boxShadow: "none",
        border: "none",
        background: "transparent",
      },
      "@media screen and (max-width: 767px)": {
        padding: "0.75rem",
      },
    },
  });

function Content() {
  const location = useLocation();
  const history = useHistory();
  const { otp, accountToken } = useParams();
  const { i18n, t } = useTranslation();
  const customToast = useToast();
  const currentDirection = i18n.dir();
  const { dispatch } = useContext(DemoContext);
  const [activeStep, setActiveStep] = useState(-1);
  const classes = useStyles(currentDirection)();

  const autoVerifyOtp = async () => {
    const queryParam = new URLSearchParams(location.search);
    const authToken = queryParam.get("authToken");
    if (authToken) {
      try {
        const data = {
          _language: i18n.language,
          _authToken: authToken,
          _otp: otp,
        };
        const response = await postVerifyOTP(data);
        if (response._statusCode === 200) {
          const {
            _accountToken: accountToken,
            _firstName: firstName,
            _lastName: lastName,
            _schoolName: schoolName,
            _mobileNumber: mobile,
            _countryCode: callingCode,
            _email: email,
          } = response._entity;
          const submittedInfo = {
            accountToken,
            firstName,
            lastName,
            schoolName,
            mobile,
            callingCode,
            email,
          };
          dispatch({ type: "userInfo", payload: submittedInfo });
          setActiveStep(3);
        } else if (response._statusCode === 597) {
          customToast(
            t("Note"),
            t("Trial already verified, and under approval"),
            i18n.dir() === "rtl",
            "info"
          );
          dispatch({ type: "resetForm" });
        } else throw response;
      } catch (err) {
        console.log(err);
        customToast(
          t("Error Label"),
          t("InvalidLink"),
          i18n.dir() === "rtl",
          "error"
        );
        history.push("/");
      }
    }
  };

  const autoApproveTrial = async () => {
    if (accountToken) {
      try {
        const response = await approveTrial(accountToken);
        if (response._statusCode === 200) {
          customToast(
            t("Approved"),
            t("Trial approved"),
            i18n.dir() === "rtl",
            "success"
          );
        } else if (response._statusCode === 215) {
          customToast(
            t("Note"),
            t("Trial already approved"),
            i18n.dir() === "rtl",
            "info"
          );
        } else throw response;
      } catch (err) {
        console.log(err);
        customToast(
          t("Error Label"),
          t("InvalidAccount"),
          i18n.dir() === "rtl",
          "error"
        );
      }
    }
  };

  const autoUnapproveTrial = async () => {
    if (accountToken) {
      try {
        const response = await unapproveTrial(accountToken);
        if (response._statusCode === 200) {
          customToast(
            t("Disapproved"),
            t("Trial unapproved"),
            i18n.dir() === "rtl",
            "success"
          );
        } else if (response._statusCode === 599) {
          customToast(
            t("Note"),
            t("Trial already unapproved"),
            i18n.dir() === "rtl",
            "info"
          );
        } else if (response._statusCode === 215) {
          customToast(
            t("Note"),
            t("Can not disapprove the approved"),
            i18n.dir() === "rtl",
            "info"
          );
        } else throw response;
      } catch (err) {
        console.log(err);
        customToast(
          t("Error Label"),
          t("InvalidAccount"),
          i18n.dir() === "rtl",
          "error"
        );
      }
    }
  };

  const extendTrial = () => {
    setActiveStep(-2);
  };

  useEffect(() => {
    if (location.pathname.includes("/verify-otp/")) autoVerifyOtp();
    if (location.pathname.includes("/demo/approve/")) autoApproveTrial();
    if (location.pathname.includes("/demo/disapprove/")) autoUnapproveTrial();
    if (location.pathname.includes("/demo/extend/")) extendTrial();
  }, [location]);

  const renderStep = () => {
    switch (activeStep) {
      case 0:
        return <StepOneRegister setActiveStep={setActiveStep} />;
      case 1:
        return <StepTwoRegister setActiveStep={setActiveStep} />;
      case -2:
        return <ExtendTrial setActiveStep={setActiveStep} />;
      case 3:
        return <StepThreeRegister setActiveStep={setActiveStep} />;
      default:
        return <VerifyEmail setActiveStep={setActiveStep} />;
    }
  };

  return (
    <header id="header" className={classes.homeHeader}>
      <img
        className={classes.doodle}
        src={currentDirection === "rtl" ? rtlDoodle : ltrDoodle}
        alt="doodle image"
      />
      <img
        className={classes.blueCloud}
        src={currentDirection === "rtl" ? rtlBlueCloud : ltrBlueCloud}
        alt="blue cloud image"
      />

      <Grid container sx={{ pt: 15 }}>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box className={classes.registrationForm}>
            {activeStep >= 0 && <HorizontalStepper activeStep={activeStep} />}
            {renderStep()}
          </Box>
        </Grid>
      </Grid>
    </header>
  );
}

export default Content;
