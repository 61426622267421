import React, { useContext, useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import { Grid } from "@mui/material";
import { ErrorIcon } from "../../../../components/SVGs/index";
import { useTranslation } from "react-i18next";
import { DemoContext } from "../../../../contexts/Demo";
import classNames from "classnames";
import { makeStyles } from "@mui/styles";
import Button from "../../../../components/Button";
import { postResendOTP, postVerifyOTP } from "../../../../services/Demo";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import useToast from "../../../../hooks/useToast";

const customStyles = {
  backBtn: {
    display: "flex",
    justifyContent: "center",
    "@media screen and  (max-width: 767px)": {
      justifyContent: "center",
    },
  },
};

const useStyles = makeStyles({
  otpcontainer: {
    justifyContent: "center",
    direction: "ltr",
    "&> div> input": {
      border: "1px solid rgba(217, 216, 216, 1)",
      height: "3rem",
      width: "4rem !important",
      fontSize: "1.5rem",
      borderRadius: "0.25rem",
      "@media only screen and (max-width: 414px)": {
        height: "2.5rem",
        width: "3rem !important",
        fontSize: "1rem",
      },
    },
    "& > div": {
      margin: "0 0.75rem",
    },
    "& > div > input:hover": {
      borderColor: "rgb(0 0 0 /87%)",
    },
    "& > div > input:focus-within": {
      outlineColor: "rgb(98 195 238)",
    },
    "& > div > input:focus": {
      border: "2px solid rgb(98 195 238)",
    },
  },
  errors: {
    "& input": { color: "rgb(245 79 100)" },
  },
});

const errorTypes = {
  387: "WrongOTP",
  389: "WrongOTP",
  236: "AlreadyTrial",
  388: "EmailValidation",
  386: "EmailNotExist",
};

const arbToEng = {
  "\u0660": 0,
  "\u0661": 1,
  "\u0662": 2,
  "\u0663": 3,
  "\u0664": 4,
  "\u0665": 5,
  "\u0666": 6,
  "\u0667": 7,
  "\u0668": 8,
  "\u0669": 9,
};

function StepTwoRegister({ setActiveStep }) {
  const { t, i18n } = useTranslation();
  const { state, dispatch } = useContext(DemoContext);
  const [resendOtpDisable, setResendOtpDisable] = useState(false);
  const customToast = useToast();
  const { userInfo } = state;
  const [otp, setOtp] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [errors, setErrors] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setIsValid(Object.keys(errors).length === 0 && otp.length === 4);
  }, [errors]);

  useEffect(() => {
    let validOtp = otp;
    Object.keys(arbToEng).forEach((num) => {
      if (otp.includes(num)) {
        validOtp = otp.replace(num, arbToEng[num]);
      }
    });

    if (/^\d{4}$/.test(validOtp)) setIsValid(true);
    setOtp(validOtp);
  }, [otp]);

  const onOtpChange = (otpVar) => {
    setIsValid(false);
    setErrors(false);
    if (/^\d{4}$/.test(otp)) setIsValid(true);
    setOtp(otpVar);
  };

  const resendHandler = async () => {
    setResendOtpDisable(true);
    try {
      const data = {
        _language: i18n.language,
        _authToken: userInfo.authToken,
      };
      const response = await postResendOTP(data);
      if (response) {
        if (response._statusCode === 200)
          customToast(
            t("StepOneMsgLabel"),
            t("ResendMessage"),
            i18n.dir() === "rtl"
          );
        else if (response._statusCode === 597) {
          customToast(
            t("Note"),
            t("Trial already verified, and under approval"),
            i18n.dir() === "rtl",
            "info"
          );
          dispatch({ type: "resetForm" });
          setActiveStep(-1);
        }
      }
    } catch (error) {
      console.log(error);
    }
    setResendOtpDisable(false);
  };

  const verfiyHandler = async () => {
    setIsValid(false);
    const data = {
      _language: i18n.language,
      _authToken: userInfo.authToken,
      _otp: otp,
    };
    try {
      const response = await postVerifyOTP(data);
      if (response._statusCode === 200) {
        const updateInfo = {
          ...userInfo,
          accountToken: response._entity._accountToken,
        };
        dispatch({ type: "userInfo", payload: updateInfo });
        setActiveStep(3);
      } else if (response._statusCode === 597) {
        customToast(
          t("Note"),
          t("Trial already verified, and under approval"),
          i18n.dir() === "rtl",
          "info"
        );
        dispatch({ type: "resetForm" });
        setActiveStep(-1);
      } else {
        setErrors({ ...errors, OTP: response._statusCode });
      }
    } catch (err) {
      console.log(err);
      toast.error(
        t("technical issue"),
        {
          toastId: "technicalIssue",
          closeButton: true,
          style: { width: "fit-content" },
          bodyStyle: { textAlign: "right" },
        },
        i18n.dir() === "rtl"
      );
    }
  };

  return (
    <Grid container rowSpacing={5} sx={{ pt: "30px", textAlign: "center" }}>
      <Grid item xs={12} style={{ color: "rgb(105 123 140)" }}>
        <p style={{ fontSize: "16px" }}>{t("Demo Steptwo Title1")}</p>
        {userInfo.email && (
          <p style={{ fontSize: "16px", color: "rgb(98 195 238)" }}>
            {userInfo.email}
          </p>
        )}
        <p style={{ fontSize: "16px" }}>{t("Demo Steptwo Title2")}</p>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          height: "80px",
        }}
      >
        <div style={{ position: "absolute", top: 0, padding: "20px 0" }}>
          <OtpInput
            value={otp}
            onChange={onOtpChange}
            numInputs={4}
            shouldAutoFocus={true}
            containerStyle={classNames(
              classes.otpcontainer,
              errors.OTP && classes.errors
            )}
            required
          />
          {errorTypes[errors.OTP] ? (
            <p>
              <ErrorIcon style={{ fontSize: "1.25rem" }} />
              <span style={{ fontSize: "0.75rem", color: "rgb(245 79 100)" }}>
                {t(errorTypes[errors.OTP])}
              </span>
            </p>
          ) : null}
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Button
          action={verfiyHandler}
          disable={!isValid}
          label={t("Create Account")}
          styles={{ width: "350px", maxWidth: "90%" }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <span
          onClick={resendHandler}
          style={{
            pointerEvents: resendOtpDisable ? "none" : null,
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          {t("Resend OTP")}
        </span>
      </Grid>
      <Grid item xs={12} sx={customStyles.backBtn}>
        <Button
          white
          label={t("Go Back")}
          styles={{ width: "149px" }}
          action={() => setActiveStep(0)}
        />
      </Grid>
    </Grid>
  );
}

StepTwoRegister.propTypes = {
  setActiveStep: PropTypes.func.isRequired,
};

export default StepTwoRegister;
