import React, { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import firebase from "./analytics";
import PropTypes from "prop-types";

export const FireBaseContext = createContext();

function FireBaseContextProvider(props) {
  const [isAppMounted, setAppMounted] = useState(false);
  const { pathname: url } = useLocation();

  useEffect(() => {
    setAppMounted(true);
  }, []);

  const logEvent = (name, additionalData = {}) => {
    let loggingData = {
      Source: url,
      ...additionalData,
    };
    firebase.analytics().logEvent(name, loggingData);
  };

  return (
    <FireBaseContext.Provider value={{ logEvent, isAppMounted }}>
      {props.children}
    </FireBaseContext.Provider>
  );
}

FireBaseContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export default FireBaseContextProvider;
