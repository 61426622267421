import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import classNames from "classnames";

const useStyles = (styles) =>
  makeStyles({
    root: {
      minWidth: "fit-content",
      height: "38px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "rgb(98 195 238)",
      borderRadius: "8px",
      color: "rgb(255 255 255)",
      fontSize: "16px",
      cursor: "pointer",
      userSelect: "none",
      border: "none",
      outline: "none",
      "&:active": {
        boxShadow: "inset 0px 5px 3px 0px rgb(0 0 0/10%)",
      },
      "&:hover": {
        background: "rgb(119 204 241)",
      },
      ...styles,
    },
    white: {
      color: "rgba(37, 37, 37, 1)",
      background: "rgba(255 255 255)",
      border: "1px solid rgb(98 195 238)",
      "&:hover": {
        background: "rgba(237, 250, 255, 1)",
      },
      "&:active": {
        background: "rgba(255 255 255)",
        boxShadow: "inset 0 2px 6px 0 rgba(98,195,238,0.40)",
      },
      "& svg": {
        color: "rgba(108, 206, 245, 1)",
      },
    },
    disable: {
      background: "rgb(223,223,223)",
      cursor: "default",
      pointerEvents: "none",
    },
    whiteDisable: {
      color: "rgba(209, 209, 209, 1)",
      background: "rgba(255, 255, 255, 1)",
      cursor: "default",
      pointerEvents: "none",
      "& svg": {
        color: "rgba(230, 230, 230, 1)",
      },
    },
  });

const defaultStyles = {
  width: "100%",
};
function Button({
  label,
  action,
  Icon = null,
  disable = false,
  white = false,
  styles = defaultStyles,
}) {
  const classes = useStyles(styles)();
  return (
    <button
      className={classNames(
        classes.root,
        disable && !white && classes.disable,
        white && !disable && classes.white,
        white && disable && classes.whiteDisable
      )}
      onClick={action}
      type={action ? "button" : "submit"}
    >
      {Icon}
      {label}
    </button>
  );
}

Button.propTypes = {
  label: PropTypes.string.isRequired,
  action: PropTypes.func,
  disable: PropTypes.bool,
  white: PropTypes.bool,
  styles: PropTypes.object,
  Icon: PropTypes.node,
};

export default Button;
