import React, {useEffect, useRef} from "react";
import {makeStyles} from "@mui/styles";
import useIsInViewport from "../../hooks/useIsInViewport";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import VideoPlayer from "../VideoPlayer";
import videoThumbnail from "../../assets/images/videoThumbnail.png";
import bookThumbnail from "../../assets/images/bookThumbnail.png";
import quizThumbnail from "../../assets/images/quizThumbnail.png";
import micThumbnail from "../../assets/images/micThumbnail.png";
import rewardThumbnail from "../../assets/images/rewardThumbnail.png";


const useStyles = () => makeStyles({
    video: {
        width: "350px",
        position: "relative",
        zIndex: "0",
        backgroundColor: "rgba(220, 222, 230, 1)",
        marginTop: "60px",
        marginLeft: "30px",
        marginRight: "30px",
        "@media (min-width: 768px)": {
            width: "630px",
            borderRadius: "15px",
        },
    },
    pageDescription: {
        marginRight: "30px",
        marginLeft: "30px",
        fontWeight: "lighter",
        fontSize: "18px",
        marginTop: "30px",
        textAlign: "initial",
        lineHeight: "41px",
        fontStyle: "normal",
        color: "rgb(90,80,76)",
    },
    sectionDetails: {
        overflow: "hidden",
        textAlign: "center"
    }
});

function GuidedReadingSectionDetails({setProgressState}) {
    const classes = useStyles()();
    const {t} = useTranslation();
    const refOfFirstVideo = useRef(null);
    const refOfVideoDescription = useRef(null);
    const refOfBookSectionEnd = useRef(null);
    const refOfQuizSectionEnd = useRef(null);
    const refOfMicSectionEnd = useRef(null);
    const refOfRewardSectionEnd = useRef(null);

    const isFirstVideoVisible = useIsInViewport(refOfFirstVideo);
    const isVideoDescriptionVisible = useIsInViewport(refOfVideoDescription);
    const isBookSectionEndVisible = useIsInViewport(refOfBookSectionEnd);
    const isQuizSectionEndVisible = useIsInViewport(refOfQuizSectionEnd);
    const isMicSectionEndVisible = useIsInViewport(refOfMicSectionEnd);
    const isRewardSectionEndVisible = useIsInViewport(refOfRewardSectionEnd);

    useEffect(() => {
        isFirstVideoVisible && setProgressState(0);
        isVideoDescriptionVisible && setProgressState(1);
        isBookSectionEndVisible && setProgressState(2);
        isQuizSectionEndVisible && setProgressState(3);
        isMicSectionEndVisible && setProgressState(4);
        isRewardSectionEndVisible && setProgressState(5);
    }, [isVideoDescriptionVisible, isBookSectionEndVisible,
        isQuizSectionEndVisible, isMicSectionEndVisible, isRewardSectionEndVisible, isFirstVideoVisible]);


    return (
        <div className={classes.sectionDetails}>
            <div id="video">
                <div className={classes.video} ref={refOfFirstVideo}>
                    <VideoPlayer currentVideo={
                        process.env.REACT_APP_STORAGE + "GuidedReadingStep1.mp4"}
                                 poster={videoThumbnail}
                    />
                </div>
                <div className={classes.pageDescription}>{t("Guided Reading Video Description")}</div>
                <div ref={refOfVideoDescription} >-----------</div>
            </div>
            <div id="book">
                <div className={classes.video}>
                    <VideoPlayer currentVideo={
                        process.env.REACT_APP_STORAGE + "GuidedReadingStep2.mp4"}
                                 poster={bookThumbnail}/>
                </div>
                <div className={classes.pageDescription}>{t("Guided Reading Book Description")}</div>
                <div ref={refOfBookSectionEnd}>-----------</div>
            </div>
            <div id="quiz">
                <div className={classes.video}>
                    <VideoPlayer currentVideo={
                        process.env.REACT_APP_STORAGE + "GuidedReadingStep3.mp4"}
                                 poster={quizThumbnail}/>
                </div>
                <div className={classes.pageDescription}>{t("Guided Reading Quiz Description")}</div>
                <div ref={refOfQuizSectionEnd}>-----------</div>
            </div>
            <div id="mic">
                <div className={classes.video}>
                    <VideoPlayer currentVideo={
                        process.env.REACT_APP_STORAGE + "GuidedReadingStep4.mp4"}
                                 poster={micThumbnail}/>
                </div>
                <div className={classes.pageDescription}>{t("Guided Reading Mic Description")}</div>
                <div ref={refOfMicSectionEnd}>-----------</div>
            </div>
            <div id="reward">
                <div className={classes.video}>
                    <VideoPlayer currentVideo={
                        process.env.REACT_APP_STORAGE + "GuidedReadingStep5.mp4"}
                                 poster={rewardThumbnail}/>
                </div>
                <div className={classes.pageDescription}
                     ref={refOfRewardSectionEnd}>{t("Guided Reading Reward Description")}</div>
            </div>
        </div>)
}


GuidedReadingSectionDetails.propTypes = {
    setProgressState: PropTypes.func
}

export default GuidedReadingSectionDetails;