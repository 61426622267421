import React, { createContext, useReducer, useEffect } from "react";
import { getCountryList } from "../../services/Common";
import PropTypes from "prop-types";

const initUserInfo = {
  acceptAgreement: false,
  callingCode: "+971",
};

const initialState = {
  callingCodes: [],
  userInfo: initUserInfo,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "callingCodes":
      return { ...state, callingCodes: action.payload };
    case "stepTwo":
      return { ...state, stepTwo: action.payload };
    case "resetForm":
      return { ...initialState, callingCodes: state.callingCodes };
    case "userInfo":
      return { ...state, userInfo: action.payload };
    default:
      return { ...state };
  }
};

export const DemoContext = createContext();

const DemoContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    setCountryList();
  }, []);

  const setCountryList = async () => {
    let arCountries = [];
    let engCountries = [];
    let codeList = [];
    try {
      const response = await getCountryList();
      if (response._statusCode === 200) {
        const { _entity: data } = response;
        if (data.length > 0) {
          arCountries = data.map((country) => ({
            value: country._id,
            label: country._nameAr,
          }));
          engCountries = data.map((country) => ({
            value: country._id,
            label: country._nameEn,
          }));
          codeList = data.map((country) => country._callingCode);
        }
        arCountries.sort((a, b) => (a.label > b.label ? 1 : -1));
        engCountries.sort((a, b) => (a.label > b.label ? 1 : -1));
        codeList.sort((a, b) => (a > b ? 1 : -1));
        codeList = [...new Set(codeList)];
        codeList = codeList.map((code) => ({
          value: code,
          label: (
            <span
              style={{
                direction: "ltr",
                display: "block",
                whiteSpace: "nowrap",
              }}
            >
              {code}
            </span>
          ),
        }));
        dispatch({ type: "countries", payload: { arCountries, engCountries } });
        dispatch({ type: "callingCodes", payload: codeList });
      } else throw response;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <DemoContext.Provider value={{ state, dispatch }}>
      {props.children}
    </DemoContext.Provider>
  );
};

DemoContextProvider.propTypes = {
  children: PropTypes.node,
};

export default DemoContextProvider;
