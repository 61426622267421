import React from "react";
import yellowMark from "../../assets/images/yellow-mark-guided-page.svg";
import blueMark from "../../assets/images/blue-mark-guided-reading.png";
import {makeStyles} from "@mui/styles";

const useStyles = () => makeStyles({
    yellowMark: {
        position: "fixed",
        right: "380px",
        top: "250px",
        zIndex: 4,
        "@media (min-width: 768px)": {
            left: 0
        },
    },
    blueMark: {
        position: "fixed",
        right: 0,
        top: "10px",
        zIndex: 4,
        "@media (min-width: 768px)": {
            top: "100px"
        },
    }
});


function BackgroundOfGuidedReading() {
    const classes = useStyles()();

    return (
        <div>
            <img className={classes.yellowMark} src={yellowMark} alt="doodle image"/>
            <img className={classes.blueMark} src={blueMark} alt="doodle image"/>
        </div>
    )
}

export default BackgroundOfGuidedReading;