import React from "react";
import Header from "./sections/Header";
import Content from "./sections/Content";
import Footer from "../../../components/Footer";

function TeachersPlatform() {
  return (
    <>
      <Header />
      <Content />
      <Footer />
    </>
  );
}

export default TeachersPlatform;
