import React, { useState, useContext, useRef, useEffect } from "react";
import { makeStyles } from "@mui/styles";
// import { FireBaseContext } from "../../../../contexts/FireBase";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import Input from "../../../../components/Input";
import SelectInput from "../../../../components/SelectInput";
import Button from "../../../../components/Button";
import { DemoContext } from "../../../../contexts/Demo";
import CheckboxInput from "../../../../components/CheckboxInput";
import TrialSchema from "./ValidationSchema";
import Joi from "joi";
import PropTypes from "prop-types";
import useValidation from "../../../../hooks/useValidation";
import {
  postRegistration,
  updateRegistration,
} from "../../../../services/Demo";
import useToast from "../../../../hooks/useToast";
import { toast } from "react-toastify";

const docLinks = {
  termsAndConditions:
    "https://www.alefeducation.com/wp-content/uploads/2020/08/AlefPlatformUserTerms.pdf",
  privacyAr: "",
  privacyEn: "",
};

const useStyles = makeStyles({
  acceptLinks: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(81 81 81)",
    fontSize: "14px",
    whiteSpace: "nowrap",
    "& > a ": {
      padding: "0 5px",
      color: "rgb(81 81 81)",
      fontWeight: "bold",
    },
    "@media screen and  (max-width: 767px)": {
      fontSize: "12px",
    },
  },
});

const customStyles = {
  name: {
    paddingBottom: "25px",
    "@media screen and  (max-width: 768px)": {
      paddingBottom: "40px",
    },
  },
  schoolName: {
    paddingBottom: "50px",
    "@media screen and  (max-width: 767px)": {
      paddingBottom: "50px",
    },
  },
};

const errorTypes = {
  388: "EmailValidation",
};

const arbToEng = {
  "\u0660": 0,
  "\u0661": 1,
  "\u0662": 2,
  "\u0663": 3,
  "\u0664": 4,
  "\u0665": 5,
  "\u0666": 6,
  "\u0667": 7,
  "\u0668": 8,
  "\u0669": 9,
};

function StepOneRegister({ setActiveStep }) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const { state, dispatch } = useContext(DemoContext);
  const { userInfo } = state;
  const [formData, setFormData] = useState(userInfo);
  const [errors, setErrors] = useState({});
  // const { logEvent } = useContext(FireBaseContext);
  const { callingCodes } = state;
  const [isValid, setIsValid] = useState(false);
  const { validateProperty } = useValidation();
  const customToast = useToast();
  const emailRef = useRef(null);

  useEffect(() => {
    setIsValid(Object.keys(errors).length === 0);
  }, [errors]);

  useEffect(() => {
    const updateErrors = validate(TrialSchema);
    setIsValid(Object.keys(updateErrors).length === 0);
  }, [formData]);

  const changeHandler = (event) => {
    const data = { ...formData };
    const { target: input } = event;
    const errorsObject = { ...errors };
    if ("mobile" === input.name) {
      input.value = input.value.toString().trim();
      Object.keys(arbToEng).forEach((num) => {
        if (input.value.includes(num)) {
          input.value = input.value.replace(num, arbToEng[num]);
        }
      });
    }

    const errorMesssage = validateProperty(input, TrialSchema);

    if (errorMesssage) errorsObject[input.name] = errorMesssage;
    else delete errorsObject[input.name];
    setErrors(errorsObject);
    setIsValid(Object.keys(errorsObject).length === 0);
    data[input.name] = input.value;
    setFormData(data);
  };

  const callingCodeChangeHandler = (event) => {
    setFormData({ ...formData, callingCode: event.currentTarget.value });
  };

  const validate = (schema) => {
    const options = { abortEarly: false };
    const { error } = Joi.object(schema).validate(formData, options);
    let errorsObject = { ...errors };
    if (error)
      for (let item of error.details) errorsObject[item.path[0]] = item.message;
    return errorsObject;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!isValid) return;
    const payload = {
      _authToken: formData.authToken,
      _firstName: formData.firstName,
      _lastName: formData.lastName,
      _schoolName: formData.schoolName,
      _language: i18n.language,
      _verifiedToken: userInfo.verifiedToken,
      _mobileNumber: formData.mobile,
      _countryCode: formData.callingCode,
    };
    const submit = formData.authToken ? updateRegistration : postRegistration;
    setIsValid(false);
    try {
      const response = await submit(payload);
      if (response && response._statusCode === 200) {
        const authToken = response._entity._authToken;
        const updatedForm = { ...formData, authToken };
        setFormData(updatedForm);
        dispatch({ type: "userInfo", payload: updatedForm });
        setActiveStep(1);
      } else if (response._statusCode === 597) {
        customToast(
          t("Note"),
          t("Trial already verified, and under approval"),
          i18n.dir() === "rtl",
          "info"
        );
        dispatch({ type: "resetForm" });
        setActiveStep(-1);
      } else if (
        response._statusCode &&
        Object.keys(errorTypes).includes(response._statusCode.toString())
      ) {
        customToast(
          i18n.t("Error Label"),
          i18n.t(errorTypes[response._statusCode]),
          i18n.dir() === "rtl",
          "error"
        );
        const errorsObject = { ...errors };
        errorsObject["email"] = errorTypes[response._statusCode];
        setErrors(errorsObject);
      } else throw response;
    } catch (error) {
      console.log(error);
      toast.error(i18n.t("technical issue"), {
        toastId: "technicalIssue",
        closeButton: true,
        style: { width: "fit-content" },
        bodyStyle: { textAlign: "right" },
      });
    }
  };

  const formVars = {
    firstName: {
      name: "firstName",
      placeholder: t("FirstName Placeholder"),
      label: t("FirstName Label"),
      defaultValue: userInfo.firstName,
    },
    lastName: {
      name: "lastName",
      placeholder: t("LastName Placeholder"),
      defaultValue: userInfo.lastName,
    },
    schoolName: {
      name: "schoolName",
      placeholder: t("School Placeholder"),
      label: t("School Label"),
      defaultValue: userInfo.schoolName,
    },
    email: {
      name: "email",
      label: t("Email"),
      defaultValue: userInfo.email,
    },
    mobile: {
      name: "mobile",
      placeholder: t("Mobile Placeholder"),
      defaultValue: userInfo.mobile,
    },
    callingCode: {
      name: "callingCode",
      defaultValue: userInfo.callingCode,
      valueList: callingCodes,
    },
    acceptAgreement: {
      name: "acceptAgreement",
      defaultValue: userInfo.acceptAgreement || false,
    },
  };

  const handleGoBack = () => {
    dispatch({ type: "resetForm" });
    setActiveStep(-1);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid
        container
        rowSpacing={5}
        sx={{
          pt: "30px",
          textAlign: "center",
        }}
      >
        <Grid item xs={12}>
          <p style={{ fontSize: "16px", color: "rgb(105 123 140)" }}>
            {t("Demo Stepone Title")}
          </p>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            columnSpacing={6}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <Grid item xs={12} sm={5} sx={customStyles.schoolName}>
              <Input
                {...formVars.schoolName}
                errors={t(errors.schoolName)}
                onChange={changeHandler}
                autoFocus
              />
            </Grid>

            <Grid item xs={12} sm={7} style={{ paddingBottom: "25px" }}>
              <Grid container spacing={1} alignItems="flex-end">
                <Grid item xs={12} sm={6} sx={customStyles.name}>
                  <Input
                    {...formVars.firstName}
                    onChange={changeHandler}
                    errors={t(errors.firstName)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} sx={customStyles.name}>
                  <Input
                    {...formVars.lastName}
                    onChange={changeHandler}
                    errors={t(errors.lastName)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: 0 }}>
          <Grid
            container
            columnSpacing={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid item xs={12} sm={5} style={{ paddingBottom: "35px" }}>
              <Input
                inputRef={emailRef}
                {...formVars.email}
                placeholder={t("Trial Email Placeholder")}
                onChange={changeHandler}
                errors={t(errors.email)}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={7}>
              <Grid
                container
                spacing={1}
                alignItems="flex-end"
                sx={{
                  flexDirection: i18n.dir() === "rtl" ? "row" : "row-reverse",
                }}
                style={{ paddingBottom: "35px" }}
              >
                <Grid item xs={8}>
                  <Input
                    {...formVars.mobile}
                    label={i18n.dir() === "rtl" ? t("Mobile") : ""}
                    onChange={changeHandler}
                    errors={t(errors.mobile)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <SelectInput
                    {...formVars.callingCode}
                    getOption={callingCodeChangeHandler}
                    label={i18n.dir() === "ltr" ? t("Mobile") : ""}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.acceptLinks}
          style={{ paddingTop: "20px" }}
        >
          <CheckboxInput
            color="primary"
            size="small"
            onChange={changeHandler}
            {...formVars.acceptAgreement}
          />
          <span>{t("I Agree")}</span>
          <a
            href={docLinks.termsAndConditions}
            rel="noopener noreferrer"
            target="_blank"
          >
            <span>{t("Terms Of Use")}</span>
            {/* </a> */}
            <span>{` ${t("And")} `}</span>
            {/* <a
            href={
              i18n.language === "ar" ? docLinks.privacyAr : docLinks.privacyEn
            }
            rel="noopener noreferrer"
          > */}
            <span>{t("Privacy Policy")}</span>
          </a>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          style={{ paddingTop: "5px" }}
        >
          <Button
            label={t("Send OTP")}
            styles={{ width: "259px" }}
            disable={!isValid}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          style={{ paddingTop: "25px" }}
        >
          <Button
            label={t("Go Back")}
            styles={{ width: "170px" }}
            white
            action={handleGoBack}
          />
        </Grid>
      </Grid>
    </form>
  );
}

StepOneRegister.propTypes = {
  setActiveStep: PropTypes.func,
};

export default StepOneRegister;
