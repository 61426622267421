import React, { useEffect, useState, useRef, useContext } from "react";
import LanguageSwitch from "./LanguageSwitch";
import { useTranslation } from "react-i18next";
import { BigLogoIcon, FacebookIcon, TwitterIcon } from "../SVGs/index";
import { useHistory, useLocation } from "react-router-dom";
import classNames from "classnames";
import { FireBaseContext } from "../../contexts/FireBase";

const sections = [
  {
    lable: "whoWeAre",
    path: "/",
    type: "simple",
  },
  {
    lable: "whatWeOffer",
    path: "/products",
    type: "menu",
    items: [
      {
        lable: "teachersApp",
        path: "/products/teachers-platform",
        eventName: "ClickOnWhatOfferTeacher",
      },
      {
        lable: "StudentsApp",
        path: "/products/students-application",
        eventName: "ClickOnWhatOfferStudent",
      },
    ],
  },
  {
    lable: "freeTrial",
    path: "/demo/register",
    type: "simple",
    eventName: "ClickOnFreeTrial",
  },
  {
    lable: "Teachers Login",
    eventName: "ClickOnTeachersLogin",
    type: "menu",
    items: [
      {
        lable: "teacher",
        path: "https://teachers.abjadiyat.com/",
        type: "external",
      },
      {
        lable: "student",
        path: "https://students.abjadiyat.com/",
        type: "external",
      },
      {
        lable: "principal",
        path: "https://principal.abjadiyat.com/login",
        type: "external",
      },
    ],
  },
];

function Header() {
  const [collapse, setCollapse] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [isEventLogged, setIsEventLogged] = useState(false);
  const { t, i18n } = useTranslation();
  const { pathname: url } = useLocation();
  const history = useHistory();
  const navRef = useRef(null);
  const { logEvent, isAppMounted } = useContext(FireBaseContext);

  const collapseHandler = () => {
    setShowMenu(false);
    if (navRef.current) {
      const offsetTop =
        document.documentElement.scrollTop -
        navRef.current.getBoundingClientRect().top;
      setCollapse(
        ["individuals-rights-enforcement", "thank-you", "subscription", "guided-reading", "support"].some(
          (item) => url.indexOf(item) !== -1
        ) || offsetTop > 60
      );
      if (offsetTop > 60 && !isEventLogged) setIsEventLogged(true);
    }
  };

  const handleExpand = () => {
    setShowMenu(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", collapseHandler);
    window.addEventListener("resize", handleExpand);
    return () => {
      window.removeEventListener("scroll", collapseHandler);
      window.removeEventListener("resize", handleExpand);
    };
  }, []);

  useEffect(() => {
    setCollapse(
      [
        "individuals-rights-enforcement",
        "thank-you",
        "subscription",
        "guided-reading",
        "support",
        "student-app/privacy-policy",
      ].some((item) => url.indexOf(item) !== -1)
    );
    if (!isAppMounted) {
      logEvent("VisitAbjadiyatWeb");
    }
  }, [isAppMounted]);

  useEffect(() => {
    collapseHandler();
  }, [url]);

  useEffect(() => {
    if (isEventLogged) {
      const pathName = url.split("/");
      let eventName = "";
      if (pathName[0] === "" && pathName[1] === "")
        eventName = "ScrollHomePage";
      if (pathName[2] && pathName[2] === "students-application")
        eventName = "ScrollWhatOfferStudent";
      else if (pathName[2] && pathName[2] === "teachers-platform")
        eventName = "ScrollWhatOfferTeacher";
      if (eventName) logEvent(eventName);
    }
  }, [isEventLogged]);

  const activePath = (path) => {
    return url === path || (url.includes(path) && path !== "/");
  };

  const propsLink = (item) => {
    return item.type === "external"
      ? {
          href: item.path,
          rel: "noopener noreferrer",
          onClick: () => {
            logEvent(item.eventName);
          },
        }
      : {
          onClick: () => {
            setShowMenu(false);
            setIsEventLogged(false);
            if (item.eventName) logEvent(item.eventName);
            setIsEventLogged(false);
            history.push(item.path);
          },
        };
  };
  const renderSimpleItem = (item, index) => (
    <li
      className={
        "nav-item" +
        (activePath(item.path) ? " active" : "") +
        (item.withIcon ? " withIcon" : "")
      }
      key={"item" + index}
      {...(item.type !== "external" ? propsLink(item) : {})}
    >
      <a
        className={
          "nav-link page-scroll" + (activePath(item.path) ? " active" : "")
        }
        {...(item.type === "external" ? propsLink(item) : {})}
      >
        {t(item.lable)}
      </a>
      {item.withIcon
        ? item.withIcon(
            {
              label: t("Offer"),
            },
            showMenu
          )
        : null}
    </li>
  );
  const renderMenuItem = (section, index) => (
    <li className="nav-item dropdown" key={"item" + index}>
      <a
        className={classNames(
          "nav-link page-scroll",
          activePath(section.path) ? " active" : "",
          !showMenu ? "dropdown-toggle" : ""
        )}
        style={
          showMenu
            ? {
                color: activePath(section.path)
                  ? "rgba(60 180 229)"
                  : "rgb(57,57,57)",
              }
            : {}
        }
        id="navbarDropdown"
        role="button"
      >
        {t(section.lable)}
      </a>
      <div
        className="dropdown-menu"
        aria-labelledby="navbarDropdown"
        style={{
          top: "22px",
          textAlign: i18n.dir() === "rtl" ? "right" : "left",
          ...(showMenu ? { display: "block", width: "100%" } : {}),
        }}
      >
        {section.items.map((item, index) => (
          <a
            key={"subMenuItem" + index}
            className={
              "dropdown-item" + (activePath(item.path) ? " active" : "")
            }
            onClick={() => {
              setShowMenu(false);
              if (!activePath(item.path)) logEvent(item.eventName);
              setIsEventLogged(false);
              history.push(item.path);
            }}
            {...propsLink(item)}
          >
            <span className="item-text">{t(item.lable)}</span>
          </a>
        ))}
      </div>
    </li>
  );

  const menuItmes = sections.map((section, index) =>
    section.type === "menu"
      ? renderMenuItem(section, index)
      : renderSimpleItem(section, index)
  );
  const navClass =
    "navbar navbar-expand-lg navbar-dark navbar-custom fixed-top";
  return (
    <nav
      className={navClass + (collapse ? " top-nav-collapse" : "")}
      ref={navRef}
    >
      {/* <!-- Mobile Menu Toggle Button --> */}
      <button
        className={"navbar-toggler" + (showMenu ? "" : " collapsed")}
        type="button"
        aria-label="Toggle navigation"
        onClick={() => setShowMenu(!showMenu)}
      >
        <span
          className="navbar-toggler-awesome fas fa-bars"
          style={{ display: showMenu ? "none" : "inline-block" }}
        ></span>
        <span
          className="navbar-toggler-awesome fas fa-times"
          style={{ display: showMenu ? "inline-block" : "none" }}
        ></span>
      </button>
      {/* <!-- end of mobile menu toggle button --> */}

      <div
        className={"collapse navbar-collapse" + (showMenu ? " show" : "")}
        id="navbarsExampleDefault"
      >
        <span
          className="nav-item social-icons"
          style={{
            ...(showMenu
              ? {
                  position: "absolute",
                  bottom: 0,
                  width: "100%",
                  left: "10px",
                  padding: "1rem",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  zIndex: 3,
                }
              : {}),
          }}
        >
          <span
            className="fa-stack"
            style={{
              marginRight: "8px",
              width: "2em",
            }}
          >
            <LanguageSwitch />
          </span>
          <span className="fa-stack">
            <a
              onClick={() => logEvent("ClickOnTwitter")}
              href="https://twitter.com/abjadiyatapp"
              rel="noopener noreferrer"
            >
              <TwitterIcon
                style={{ fontSize: showMenu ? "1.8rem" : "1.5rem" }}
                iconcolor={
                  collapse || showMenu ? "rgb(255 255 255)" : "rgba(60 180 229)"
                }
                circlecolor={
                  collapse || showMenu ? "rgba(60 180 229)" : "rgb(255 255 255)"
                }
              />
            </a>
          </span>

          <span className="fa-stack">
            <a
              href="https://www.facebook.com/abjadiyatapp"
              onClick={() => logEvent("ClickOnFacebook")}
              rel="noopener noreferrer"
            >
              <FacebookIcon
                style={{ fontSize: showMenu ? "1.8rem" : "1.5rem" }}
                iconcolor={
                  collapse || showMenu ? "rgb(255 255 255)" : "rgba(60 180 229)"
                }
                circlecolor={
                  collapse || showMenu ? "rgba(60 180 229)" : "rgb(255 255 255)"
                }
              />
            </a>
          </span>
        </span>
        <ul
          className={
            i18n.dir() === "rtl" ? "navbar-nav ml-auto" : "navbar-nav mr-auto"
          }
          style={{
            padding: 0,
            ...(showMenu
              ? {
                  fontSize: "1.25rem",
                  fontWeight: "bold",
                  lineHeight: "1.75rem",
                  cursor: "pointer",
                  userSelect: "none",
                  marginBottom: "3.5rem",
                }
              : {}),
            textAlign: i18n.dir() === "ltr" ? "left" : "right",
          }}
        >
          {menuItmes}
        </ul>
      </div>
      <BigLogoIcon
        onClick={() => history.push("/")}
        className="navbar-brand logo-image"
        style={
          showMenu
            ? {
                position: "absolute",
                ...(i18n.dir() === "rtl"
                  ? { left: "16px" }
                  : { right: "16px" }),
                top: "8px",
              }
            : {}
        }
      />
    </nav>
  );
}

export default Header;
