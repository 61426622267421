import privateShareImage from "../../assets/images/private_share.png";
import activitiesImage from "../../assets/images/activities.png";
import recordedBooksImage from "../../assets/images/recorded_books.png";
import entertainmentImage from "../../assets/images/entertainment.png";
import React from "react";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";

const useStyles = (dir) => makeStyles({
    details: {
        marginRight: "29px",
        marginTop: "25px",
        display: "flex",
        flexDirection: "column",
        alignItems: (dir === "ltr") ? "start" : "flex-start",
        "@media (min-width: 768px)": {
            marginRight: "0px",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-around",
            gap: "40px"
        }
    },
    subscriptionDetailPair: {
        display: "flex",
        flexDirection: "column",
        alignItems: (dir === "ltr") ? "start" : "flex-start",
        "@media (min-width: 768px)": {
            display: "flex",
            flexDirection: "column",
        }
    },
    doodle: {
        marginLeft: "1rem",
        marginRight: dir === "ltr" ? "1rem" : "0rem"
    },
    subscriptionDetail: {
        display: "flex",
        flexDirection: "row-reverse",
        marginTop: "20px",
        justifyContent: "end",
    }
});

function SubscriptionDetails() {
    const {t, i18n} = useTranslation();
    const currentDirection = i18n.dir();
    const classes = useStyles(currentDirection)();

    function getSubscriptionDetail(imageName, detail) {
        return <div className={classes.subscriptionDetail}>
            <div>{detail}</div>
            <img
                className={classes.doodle}
                src={imageName}
                alt="doodle image"
            />
        </div>;
    }

    return (
        <div className={classes.details}>
            <div className={classes.subscriptionDetailPair}>
                {getSubscriptionDetail(privateShareImage, t("Subscription Benefit 1"))}
                {getSubscriptionDetail(activitiesImage, t("Subscription Benefit 2"))}
            </div>
            <div className={classes.subscriptionDetailPair}>
                {getSubscriptionDetail(recordedBooksImage, t("Subscription Benefit 3"))}
                {getSubscriptionDetail(entertainmentImage, t("Subscription Benefit 4"))}
            </div>
        </div>
    )
}

export default SubscriptionDetails;