import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {React} from "react";
import Footer from "../../components/Footer";
import { useTranslation } from "react-i18next";
import RegSuccess from "../../assets/images/reg_succes.svg";
import {useLocation} from "react-router-dom";

const useStyles = () => 
  makeStyles({
    root: {
      display: "flex",
      alignItems : "center",
      justifyContent: "center"
    }
  });

function ThankYou() {
  const classes = useStyles()();
  const { t } = useTranslation();
  const {pathname} = useLocation();

  let thankyouText = t("thankyouText");
  if (pathname.includes("subscription")) {
      thankyouText = t("Subscribe Thank you")
  }
  if (pathname.includes("guided-reading")) {
      thankyouText = t("Guided Reading Thank you")
  }
  return (
    <>
      <Grid container sx={{pt : 20}}>
        <Grid item xs={12} sm={12} lg={12} className={classes.root}>
          <img src={RegSuccess} />
        </Grid>
        <Grid item xs={12} sm={12} lg={12} sx={{pt : 5}} className={classes.root}>
          <div style={{fontSize : "20px", margin: "auto", width: "80%", textAlign: "center"}}>{thankyouText}</div>
        </Grid>
      </Grid>
      <Footer />
    </>
);
}

export default ThankYou;