import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Switch } from "react-router-dom";
import { ApmRoute } from "@elastic/apm-rum-react";
import Header from "../../components/Header/index";
import routes from "../../routes/public";
import BackToTop from "../../components/BackToTop";
import useClientDevice from "../../hooks/useClientDevice";

function PublicLayout() {
  const { i18n } = useTranslation();
  const [direction, setDirection] = useState("rtl");
  const { isTablet, isMobile } = useClientDevice();

  useEffect(() => {
    setDirection(i18n.dir());
  }, [i18n.dir()]);

  return (
    <div style={{ direction }}>
      <Header />
      {!((isTablet ? true : false) || isMobile) && <BackToTop />}
      <Switch>
        {routes.map((route, index) => (
          <ApmRoute path={route.path} component={route.component} key={index} />
        ))}
      </Switch>
    </div>
  );
}

export default PublicLayout;
