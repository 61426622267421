import React from "react";
import { InputAdornment, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ErrorIcon } from "../SVGs";
import PropTypes from "prop-types";

const customStyles = {
  inputField: {
    ".MuiInputBase-root": {
      padding: "0",
      height: "38px",
      backgroundColor: "rgb(255 255 255)",
      fontSize: "16px",
      borderRadius: "8px",
      "& input": {
        padding: "7px 18px",
      },
      "&:hover": {
        ".MuiOutlinedInput-notchedOutline": {
          border: " 1px solid rgb(98 195 238)",
        },
      },
      "& fieldset": {
        border: "1px solid rgb(217 216 216)",
      },
    },
  },
  disabled: {
    ".MuiInputBase-root": {
      backgroundColor: "rgb(241 241 241)",
      border: "none",
      height: "38px",
      borderRadius: "8px",
      fontSize: "16px",
      "& input": {
        color: "rgb(220 220 220)",
      },
      "&:hover": {
        ".MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      "& fieldset": {
        border: "none",
      },
    },
  },
  helperText: {
    position: "absolute",
    display: "flex",
    alignItems: "flex-start",
    top: "100%",
    fontSize: "12px",
    textAlign: "initial",
    margin: "0px",
    lineHeight: "20px",
  },
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    color: "rgb(123 130 143)",
    fontSize: "16px",
    "& label": {
      fontWeight: "bold",
    },
    "& *": {
      textAlign: "initial",
      borderWidth: "1px !important",
    },
    " & legend": {
      width: 0,
    },
  },
  adornment: {
    "& p": {
      fontSize: "16px",
      borderLeft: "1px solid rgb(217 216 216)",
      paddingLeft: "8px",
    },
  },
});

function Input({
  placeholder,
  id,
  name,
  type,
  value,
  defaultValue,
  label,
  errors,
  displayErrorIcon = false,
  adornmentVal = "",
  onChange,
  onKeyPress,
  onClick,
  inputRef,
  autoFocus = false,
  style = { root: {} },
  disabled,
  ...inputProps
}) {
  const classes = useStyles();

  return (
    <div className={classes.root} style={{ ...style.root }}>
      {label && label.length > 0 ? (
        <label htmlFor={name}>{label} &nbsp;</label>
      ) : null}
      <TextField
        variant="outlined"
        autoFocus={autoFocus}
        inputRef={inputRef}
        disabled={disabled}
        type={type}
        id={id}
        name={name}
        value={value}
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={onChange}
        onClick={onClick}
        onKeyPress={onKeyPress}
        sx={{
          ...customStyles.inputField,
          ...(disabled ? customStyles.disabled : {}),
        }}
        {...inputProps}
        helperText={
          displayErrorIcon && errors ? (
            <>
              <ErrorIcon style={{ fontSize: "1.2rem" }} />
              <span>{errors}</span>
            </>
          ) : (
            errors
          )
        }
        FormHelperTextProps={{ sx: customStyles.helperText }}
        error={errors ? true : false}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" className={classes.adornment}>
              {adornmentVal}
            </InputAdornment>
          ),
        }}
        style={{
          direction:
            adornmentVal && adornmentVal.length > 0 ? "ltr" : "inherit",
        }}
      />
    </div>
  );
}

Input.propTypes = {
  errors: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  adornmentVal: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  displayErrorIcon: PropTypes.bool,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  inputRef: PropTypes.object,
  style: PropTypes.object,
};

export default Input;
