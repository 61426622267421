import Joi from "joi";

const emailLetters = new RegExp("^[a-zA-Z0-9._@]+$");
const charctersPattern = new RegExp(
  "^[A-Za-z\u0621-\u063A\u0641-\u064A _][A-Za-z\u0621-\u063A\u0641-\u064A. _]+$"
);
const onlyCharactersWithDigitPattern = new RegExp(
  "^[A-Za-z0-9\u0621-\u063A\u0641-\u064A _][A-Za-z0-9\u0621-\u063A\u0641-\u064A. _]*$"
);

const numberPattern = new RegExp("^[0-9]*$");

const TrialSchema = {
  firstName: Joi.string()
    .required()
    .pattern(charctersPattern)
    .min(3)
    .max(60)
    .messages({
      "string.empty": "First Name is required",
      "any.required": "First Name is required",
      "string.max": "NameValidation",
      "string.min": "NameValidation",
      "string.pattern.base": "NameValidation",
    }),
  lastName: Joi.string()
    .required()
    .pattern(charctersPattern)
    .min(3)
    .max(60)
    .messages({
      "string.empty": "Last Name is required",
      "any.required": "Last Name is required",
      "string.max": "NameValidation",
      "string.min": "NameValidation",
      "string.pattern.base": "NameValidation",
    }),
  schoolName: Joi.string()
    .pattern(onlyCharactersWithDigitPattern)
    .min(3)
    .max(60)
    .required()
    .messages({
      "string.empty": "School Name is required",
      "any.required": "School Name is required",
      "string.max": "GeneralNameValidation",
      "string.min": "GeneralNameValidation",
      "string.pattern.base": "GeneralNameValidation",
    }),
  mobile: Joi.string()
    .min(8)
    .max(15)
    .required()
    .pattern(numberPattern)
    .messages({
      "string.pattern.base": "mobileValidation",
      "string.empty": "Mobile is required",
      "any.required": "mobileValidation",
      "string.max": "mobileValidation",
      "string.min": "mobileValidation",
    }),
  callingCode: Joi.any(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .pattern(emailLetters)
    .messages({
      "string.empty": "Email Id is required",
      "any.required": "Email Id is required",
      "string.email": "EmailValidation",
      "string.pattern.base": "EmailLettersValidation",
    }),
  authToken: Joi.any(),
  verifiedToken: Joi.any(),
  acceptAgreement: Joi.valid(true).required().messages({
    "any.only": "acceptAgreement",
  }),
};


export const MicroSiteLeadsTrialSchema = {
    mobile: Joi.string()
        .min(8)
        .max(15)
        .required()
        .pattern(numberPattern)
        .messages({
            "string.pattern.base": "mobileValidation",
            "string.empty": "Mobile is required",
            "any.required": "mobileValidation",
            "string.max": "mobileValidation",
            "string.min": "mobileValidation",
        }),
    callingCode: Joi.any(),
    email: Joi.string()
        .email({ tlds: { allow: false } })
        .required()
        .pattern(emailLetters)
        .messages({
            "string.empty": "Email Id is required",
            "any.required": "Email Id is required",
            "string.email": "EmailValidation",
            "string.pattern.base": "EmailLettersValidation",
        }),
    teacherName: Joi.string()
        .required()
        .pattern(charctersPattern)
        .min(3)
        .max(60)
        .messages({
            "string.empty": "Teacher Name is required",
            "any.required": "Teacher Name is required",
            "string.max": "NameValidation",
            "string.min": "NameValidation",
            "string.pattern.base": "NameValidation",
        })
};

export default TrialSchema;