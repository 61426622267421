import { useTheme } from "@emotion/react";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import arabi21 from "../../../../assets/images/arabi21.png";

function Partners() {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const matchesUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const matchesDownMd = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Grid
      container
      sx={{
        padding: "30px",
      }}
      columnGap={5}
      rowGap={5}
    >
      <Grid item xs={12}>
        <Typography variant="h2" sx={{ textAlign: "center", fontSize: "40px" }}>
          {t("Partners")}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={5}
        sx={{
          display: "flex",
          justifyContent: !matchesUpMd
            ? "center"
            : i18n.dir() === "ltr"
            ? "right"
            : "left",
        }}
      >
        <a
          style={{ fontSize: "18px" }}
          rel="noopener noreferrer"
          href={`https://arabthought.org/${
            i18n.dir() === "rtl" ? "ar" : "en"
          }/arabi21/daleelarabi`}
          target={"_blank"}
        >
          <img
            src={arabi21}
            style={{
              width: "310px",
              boxShadow: "0 12px 14px 0 rgba(208, 208, 208, 0.2)",
              border: "1px solid rgb(216, 216, 216 )",
              borderRadius: "66px",
              padding: "20px",
            }}
          />
        </a>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          textAlign: !matchesUpMd
            ? "center"
            : i18n.dir() === "ltr"
            ? "left"
            : "right",

          padding: "0 30px 30px",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            width: matchesDownMd ? "100%" : "550px",
            fontSize: "18px",
            padding: "0 0 10px",
            lineHeight: "30px",
          }}
        >
          {t("Arabi21")}
        </Typography>
        <a
          style={{ fontSize: "18px", height: "100%" }}
          rel="noopener noreferrer"
          href={`https://arabthought.org/${
            i18n.dir() === "rtl" ? "ar" : "en"
          }/arabi21/daleelarabi`}
          target={"_blank"}
        >
          {t("Arabi21Website")}
        </a>
      </Grid>
    </Grid>
  );
}

export default Partners;
