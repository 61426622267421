import React, { useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import rtlELearning from "../../../../assets/images/rtl_online_education.png";
import ltrELearning from "../../../../assets/images/ltr_online_education.png";
import rtlDoodle from "../../../../assets/images/rtl_doodle_grey.png";
import rtlBlueCloud from "../../../../assets/images/rtl_blue_cloud.png";
import ltrDoodle from "../../../../assets/images/ltr_doodle_grey.png";
import ltrBlueCloud from "../../../../assets/images/ltr_blue_cloud.png";
import { makeStyles } from "@mui/styles";
import { FireBaseContext } from "../../../../contexts/FireBase";
import classNames from "classnames";

const useStyles = (dir) =>
  makeStyles({
    homeHeader: {
      height: window.innerHeight * 0.7,
      marginBottom: "8rem",
      "@media screen and (min-width: 1800px)": {
        height: window.innerHeight * 0.8,
        marginBottom: "12rem",
      },
      "@media screen and (max-width: 1200px)": {
        height: window.innerHeight * 0.6,
        marginBottom: "8rem",
      },
      "@media screen and (max-width: 1024px)": {
        height: window.innerHeight * 0.45,
        marginBottom: "4rem",
      },
      "@media screen and (max-width: 767px)": {
        height: "fit-content",
        marginBottom: 0,
        "& #welcomeText": {
          marginTop: 0,
        },
        "& .header-content": {
          padding: "6rem 0 0",
        },
      },
    },
    studentDevices: {
      position: "absolute",
      top: "200px",
      width: "20%",
      "@media screen and (max-width: 991px)": {
        display: "none",
      },
      "@media screen and (max-width: 767px)": {
        width: "85%",
        top: "240px",
        ...(dir === "rtl" ? { right: "7%" } : { left: "10%" }),
      },
      zIndex: 2,
      ...(dir === "rtl" ? { right: "15%" } : { left: "10%" }),
    },
    doodle: {
      position: "absolute",
      top: "5rem",
      width: "20%",
      ...(dir === "ltr" ? { right: 0 } : { left: 0 }),
      "@media screen and (max-width: 991px)": {
        top: "9rem",
        width: "25%",
      },
      "@media screen and (min-width: 1450px)": {
        width: "20%",
      },
      "@media screen and (max-width: 767px)": {
        display: "none",
      },
    },
    blueCloud: {
      position: "absolute",
      width: "45%",
      ...(dir === "rtl" ? { right: 0 } : { left: 0 }),
      "@media screen and (max-width: 767px)": {
        display: "none",
      },
    },
  });

function Header() {
  const { t, i18n } = useTranslation();
  const currentDirection = i18n.dir();
  const studentDevicesRef = useRef(null);
  const classes = useStyles(currentDirection)();
  const { logEvent } = useContext(FireBaseContext);

  return (
    <header
      id="header"
      className={classNames(classes.homeHeader, "home-header")}
    >
      <img
        className={classes.studentDevices}
        src={currentDirection === "rtl" ? rtlELearning : ltrELearning}
        alt="Student Devices image"
      />
      <img
        className={classes.doodle}
        src={currentDirection === "rtl" ? rtlDoodle : ltrDoodle}
        alt="doodle image"
        ref={studentDevicesRef}
      />
      <img
        className={classes.blueCloud}
        src={currentDirection === "rtl" ? rtlBlueCloud : ltrBlueCloud}
        alt="blue cloud image"
      />
      <div
        className="header justify-content-center align-items-center"
        style={{ height: "100%" }}
      >
        <div className="header-content" style={{ height: "100%" }}>
          <div className="container" style={{ height: "100%" }}>
            <div
              className="row justify-content-center align-items-center"
              style={{ height: "100%" }}
              id="welcomeContainer"
            >
              <div className="col-lg-4 col-sm-0"></div>
              <div className="col-lg-7 col-sm-9" id="welcomeText">
                <div className="text-container">
                  <h1 className="text-center">
                    <span className="turquoise">
                      {t("Welcome to Abjadiyat")}
                    </span>
                  </h1>
                  <h4 className="text-center">
                    {t("Abjadiyat Platform support")}
                  </h4>
                  <p className="p-large text-center" style={{ width: "85%" }}>
                    {t("support detials")}
                  </p>
                  <a
                    className="btn-solid-lg page-scroll"
                    onClick={() => logEvent("ClickOnAppFeatures")}
                    href="#features"
                  >
                    {t("features")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
