import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Games from "../../../../assets/images/games.png";
import Progress from "../../../../assets/images/progress.png";
import Program from "../../../../assets/images/program.png";
import World from "../../../../assets/images/world.png";
import { GoogleIcon, AppleIcon } from "../../../../components/SVGs";
import { FireBaseContext } from "../../../../contexts/FireBase";

function Content() {
  const { t } = useTranslation();
  const { logEvent } = useContext(FireBaseContext);

  return (
    <section>
      <div className="content">
        <div className="container">
          <div className="section-header mb-70">
            <div className="row text-center justify-content-center">
              <div className="col-lg-7 col-md-11" id="appText">
                <h2>{t("Abjadiyat App")}</h2>
                <p>{t("App Details line1")}</p>
                <p>{t("App Details line2")}</p>
                <p>{t("App Details line3")}</p>
                <div className="mt-70" id="downloadIcons">
                  <p style={{ fontWeight: "bold" }}>{t("Download the app")}</p>
                  <a
                    rel="noopener noreferrer"
                    className="mx-3 d-inline-block page-scroll brand-icons"
                    target="_blank"
                    onClick={() => logEvent("ClickOnDowloadAppStore")}
                    href="https://apps.apple.com/kw/app/abjadiyat-%D8%A3%D8%A8%D8%AC%D8%AF%D9%8A%D8%A7%D8%AA/id1235509585"
                  >
                    <AppleIcon style={{ fontSize: "10rem", height: "3rem" }} />
                  </a>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    className="my-3 mx-3 my-sm-0 d-inline-block page-scroll brand-icons"
                    onClick={() => logEvent("ClickOnDowloadGooglePlay")}
                    href="https://play.google.com/store/apps/details?id=com.ibdaakids.abjadiyat&hl=en_IN&gl=US"
                  >
                    <GoogleIcon style={{ fontSize: "10rem", height: "3rem" }} />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="row align-items-start">
                <div className="col-md-6">
                  <div className="box">
                    <img className="img-fluid" src={Games} alt="alternative" />
                    <h4>{t("Games")}</h4>
                    <p>{t("Games Details")}</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="box">
                    <img
                      className="img-fluid"
                      src={Progress}
                      alt="alternative"
                    />
                    <h4>{t("Student Progress")}</h4>
                    <p>{t("Student Progress Details")}</p>
                  </div>
                </div>
              </div>
              <div className="row align-items-start">
                <div className="col-md-6">
                  <div className="box">
                    <img
                      className="img-fluid"
                      src={Program}
                      alt="alternative"
                    />
                    <h4>{t("Guided Reading Program")}</h4>
                    <p>{t("Guided Reading Details")}</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="box">
                    <img className="img-fluid" src={World} alt="alternative" />
                    <h4>{t("Abjadiyat World")}</h4>
                    <p>{t("Abjadiyat World Details")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Content;
