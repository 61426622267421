import React from "react";
import TextField from '@mui/material/TextField';
import { makeStyles } from "@mui/styles";
import { ErrorIcon } from "../SVGs";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "4px",
    width : "100% !important",
    flexGrow: 1,
    color: "rgba(81, 81, 81, 1)",
    fontSize: "1rem",
    "& label": {
      fontFamily: "dubai-bold",
    },
    "& *": {
      textAlign: "initial",
    },
  },
  inputField: {
    "& > div": {
      backgroundColor: "rgb(255 255 255)",
      fontSize: "1rem",
      borderRadius: "8px",
    },
  },
  input: {
    "&:hover": {
      "& [class*='MuiOutlinedInput-notchedOutline']": {
        borderColor: "rgba(141, 141, 141, 1)",
      },
    },
  },
  helperText: {
    position: "absolute",
    display: "flex",
    alignItems: "flex-start",
    top: "100%",
    margin: 0,
  },
  error: {
    display: "flex",
    "& svg": { fontSize: "1rem", marginTop: "4px" },
  },
});

function Input({ ...props }) {
  const classes = useStyles();
  const { inputProps, name, label, defaultValue, value, onChange, errors, displayErrorIcon = false } = props;

  return (
    <div className={classes.root}>
      {label && label.length > 0 ? (
        <label htmlFor={name}>{label} &nbsp;</label>
      ) : null}
      <TextField
        variant="filled"
        size="small"
        multiline={true}
        minRows={4}
        maxRows={20}
        value={value}
        name={name}
        sx={{
          border : "1px solid #DFDFDF",
          borderRadius: "3px",
          '&:hover': {
            border : "1px solid #3cb4e5",
            borderRadius: "3px"
          },
          '& .MuiInputBase-multiline' : {
            backgroundColor : "#ffffff !important"
          }
        }}
        defaultValue={defaultValue}
        onChange={onChange}
        {...inputProps}
        helperText={
          displayErrorIcon && errors ? (
            <>
              <ErrorIcon style={{ fontSize: "1rem" }} />
              <span>{errors}</span>
            </>
          ) : (
            errors
          )
        }
        FormHelperTextProps={{
          classes: {
            root: classes.helperText,
          },
        }}
        error={errors ? true : false}
        InputProps={{
          classes: {
            root: classes.input,
          },
          disableUnderline: true
        }}
      />
    </div>
  );
}

Input.propTypes = {
  inputProps: PropTypes.object,
  errors: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  displayErrorIcon: PropTypes.bool,
};

export default Input;
