import axios from "axios";
import { toast } from "react-toastify";
import i18n from "../i18n";

let API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Access-Control-Allow-Origin": process.env.REACT_APP_ACL_ORIGIN,
    "Content-type": "application/json",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
  },
});

API.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;
  if (!expectedError) {
    console.log(error);
    toast.error(i18n.t("technical issue"), {
      toastId: "technicalIssue",
      closeButton: true,
      style: { width: "fit-content" },
      bodyStyle: { textAlign: "right" },
    });
  }
  return Promise.reject(error);
});

export default API;
