import React, {useContext} from "react";
import {makeStyles} from "@mui/styles";
import PropTypes from "prop-types";
import {createOrderAndSession} from "../../stripe/createOrderAndSession";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import {FireBaseContext} from "../../contexts/FireBase";

const useStyles = makeStyles({
    plan: {
        border: "1px solid rgba(236 234 236)",
        borderRadius: "6px",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        "@media (min-width: 768px)": {
            width: "12.2rem",
            height: "12rem", justifyContent: "center",
        }
    },
    planName: {
        width: "9.25rem",
        backgroundColor: "rgba(60, 180, 229, 1)",
        color: "rgba(255, 255, 255, 1)",
        height: "2.5rem",
        borderRadius: "6px",
        fontSize: "1.25rem",
        margin: "2px",
        textAlign: "center",
        paddingTop: "8px",
        backgroundImage: "linear-gradient(-133deg, rgba(111, 219, 244, 1) 0%, rgba(60, 180, 229, 1) 100%)",
        "@media (min-width: 768px)": {
            width: "11.45rem",
            marginRight: "5px",
            marginLeft: "5px",
            marginTop: "4.5px"
        }
    },
    priceOfPlan: {
        fontSize: "1rem",
        fontWeight: "bold",
        color: "rgba(62, 77, 91, 1)",
        marginTop: "1rem",
        marginBottom: "0.75rem",
        "@media (min-width: 768px)": {
            marginBottom: "0.25rem",
            marginTop: "1.25rem",
        },
    },
    totalPriceOfPlan: {
        backgroundColor: 'rgba(233, 233, 233, 1)',
        borderRadius: '8px',
        justifyContent: "center",
        marginRight: "2.5rem",
        marginLeft: "2.5rem",
        marginBottom: "0.6rem",
        fontSize: "0.5rem",
        color: "rgba(132, 132, 132, 1)",
        "@media (min-width: 768px)": {
            width: "88px",
            marginRight: "53px",
            marginLeft: "53px",
            borderRadius: '10px',
            marginBottom: "25px",
        },
    },
    emptyTotalPriceOfPlan: {
        justifyContent: "center",
        marginRight: "2.5rem",
        marginLeft: "2.5rem",
        marginBottom: "0.5rem",
        fontSize: "0.5rem",
        height: "1.5rem",
        color: "rgba(132, 132, 132, 1)",
        "@media (min-width: 768px)": {
            marginBottom: "1.5rem",
        },
    },
    subscribeButton: {
        backgroundColor: 'rgba(255, 137, 95, 1)',
        color: "rgba(255, 255, 255, 1)",
        borderRadius: "0.5rem",
        border: "0",
        height: "2rem",
        width: "6.5rem",
        marginBottom: "1rem",
        fontSize: "0.65rem",
        "@media (min-width: 768px)": {
            width: "6.8rem",
            height: "38px",
            fontSize: "0.8rem"
        },
    },
    planExplanation: {
        textAlign: "center",
        fontSize: "0.6rem",
        marginTop: "0.4rem",
        width: "9.2rem",
        "@media (min-width: 768px)": {
            width: "12rem",
            fontSize: "0.75rem"
        },
    },
    offPercentage: {
        backgroundColor: 'rgba(255, 137, 95, 1)',
        color: "rgba(255, 255, 255, 1)",
        borderRadius: "0.5rem",
        width: "3rem",
        height: "1.25rem",
        fontSize: "0.6rem",
        position: "absolute",
        textAlign: "center",
        marginRight: "6rem",
        marginLeft: "1rem",
        top: "560px",
        border: "1px solid rgba(236 234 236)",
        "@media (min-width: 768px)": {
            width: "4rem",
            marginRight: "7.25rem",
            marginLeft: "1rem",
            fontSize: "0.8rem",
            top: "333px"
        },
    },
    originalPriceOfPlan: {
        textDecoration: "line-through",
        marginRight: "87px",
        marginLeft: "20px",
        marginBottom: "0px",
        position: "absolute",
        fontSize: "0.5rem",
        "@media (min-width: 768px)": {
            fontSize: "0.6rem",
            marginRight: "125px",
            marginLeft: "35px",
        },
    }
});


function SubscriptionPlan({plan, setIsSubscribing}) {
    const classes = useStyles();
    const {t} = useTranslation();
    const search = useLocation().search;
    const encodedStudentId = new URLSearchParams(search).get('studentId') || undefined;
    const encodedCardId = new URLSearchParams(search).get('cardId') || undefined;
    const cardId = atob(encodedCardId);
    const studentId = atob(encodedStudentId);
    const {logEvent} = useContext(FireBaseContext);
    const {offPercentage, planName, originalPrice, priceOfPlan, totalAmountOfPlan,
        planExplanation, eventNameOfPlan, planType} = plan;

    return <div>
        {offPercentage && <div className={classes.offPercentage}>{offPercentage}</div>}
        <div className={classes.plan}>
            <div className={classes.planName}>{planName}</div>
            {originalPrice && <p className={classes.originalPriceOfPlan}>{originalPrice}</p>}
            <p className={classes.priceOfPlan}>{priceOfPlan}</p>
            {totalAmountOfPlan ?
                <div
                    className={classes.totalPriceOfPlan}>{`${totalAmountOfPlan} ${t("Subscription Plan total amount")}`}</div>
                : <div className={classes.emptyTotalPriceOfPlan}></div>}
            <button className={classes.subscribeButton} onClick={() => {
                setIsSubscribing(true);
                logEvent(eventNameOfPlan, {STUDENT_ID: studentId});
                createOrderAndSession(planType, cardId, studentId)
            }}>
                {t("Subscribe Now button")}</button>
        </div>
        <div className={classes.planExplanation}>{planExplanation}</div>
    </div>;
}

SubscriptionPlan.propTypes = {
    plan: PropTypes.shape({
        uid: PropTypes.string,
        planName: PropTypes.string,
        priceOfPlan: PropTypes.string,
        totalAmountOfPlan: PropTypes.string,
        planExplanation: PropTypes.string,
        planType: PropTypes.string,
        eventNameOfPlan: PropTypes.string,
        offPercentage: PropTypes.string,
        originalPrice: PropTypes.string
    }),
    setIsSubscribing: PropTypes.func,
}
export default SubscriptionPlan;