import PublicLayout from "../layouts/public/index";

const routes = [
  {
    path: "/demo/extend/:accountToken",
    name: "Demo",
    component: PublicLayout,
  },
  {
    path: "/demo/approve/:accountToken",
    name: "Demo",
    component: PublicLayout,
  },
  {
    path: "/demo/disapprove/:accountToken",
    name: "Demo",
    component: PublicLayout,
  },
  {
    path: "/verify-otp/:otp",
    name: "Demo",
    component: PublicLayout,
  },
  {
    path: "/demo/register",
    name: "Demo",
    component: PublicLayout,
  },
  {
    path: "/support",
    name: "Support",
    component: PublicLayout,
  },
  {
    path: "/subscription",
    name: "Subscription",
    component: PublicLayout,
  },
  {
    path: "/guided-reading/thank-you",
    name: "Thankyou",
    component: PublicLayout,
  },
  {
    path: "/guided-reading",
    name: "GuidedReading",
    component: PublicLayout,
  },
  {
    path: "/guided-reading/free-trial",
    name: "GuidedReadingFreeTrialForm",
    component: PublicLayout,
  },
  {
    path: "/subscription/thank-you",
    name: "Thankyou",
    component: PublicLayout,
  },
  {
    path: "/thank-you",
    name: "Thankyou",
    component: PublicLayout,
  },
  {
    path: "/products/teachers-platform",
    name: "TeachersPlatform",
    component: PublicLayout,
  },
  {
    path: "/individuals-rights-enforcement",
    name: " RightsEnforcement",
    component: PublicLayout,
  },
  { path: "/", name: "Home", component: PublicLayout },
];

export default routes;
