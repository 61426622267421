import React from "react";
import { useTranslation } from "react-i18next";
import rtlBackgournd from "../../../../assets/images/rtl_testimonials_background.png";
import ltrBackgournd from "../../../../assets/images/ltr_testimonials_background.png";
import rtlTestmonials from "../../../../assets/images/rtl_testimonials.png";
import ltrTestmonials from "../../../../assets/images/ltr_testimonials.png";
import { makeStyles } from "@mui/styles";
import Carousel from "react-bootstrap/Carousel";
import { ArrowIcon } from "../../../../components/SVGs";

const useStyles = makeStyles({
  rtlBlockquote: {
    position: "relative",
    padding: "30px",
    fontFamily: "Arial Black, Helvetica, sans-serif, serif",
    color: "rgba(169 175 187)",
    quotes: "'\u201D''\u201C''\u2018''\u2019'",
    "&:before": {
      content: "open-quote",
      fontSize: "4rem",
      position: "absolute",
      right: "0",
      top: "30px",
    },
    "&:after": {
      content: "close-quote",
      position: "absolute",
      fontSize: "4rem",
      left: 0,
      bottom: "0",
    },
    "@media screen and  (max-width: 991px)": {
      "&:before": {
        right: "20px",
      },
      "&:after": {
        position: "absolute",
        left: "20px",
      },
    },
    "@media screen and  (max-width: 767px)": {
      "&:before": {
        display: "none",
      },
      "&:after": {
        display: "none",
      },
    },
  },
  ltrBlockquote: {
    padding: "30px",
    fontFamily: "Arial Black, Helvetica, sans-serif, serif",
    color: "rgba(169 175 187)",
    quotes: "'\u201C''\u201D''\u2018''\u2019'",
    "&:before": {
      content: "open-quote",
      fontSize: "4rem",
      position: "absolute",
      left: "0",
    },
    "&:after": {
      content: "close-quote",
      fontSize: "4rem",
      position: "absolute",
      right: "0",
    },

    "@media screen and  (max-width: 767px)": {
      "&:before": {
        display: "none",
      },
      "&:after": {
        display: "none",
      },
    },
  },
  testimonialsDetails: {
    width: "90%",
    textAlign: "center",
    height: "240px",
    margin: "0 auto",
    "@media (max-width: 767px)": {
      height: "300px",
      width: "100%",
    },
    "@media (max-width: 300px)": {
      height: "490px",
    },
    "@media (min-width: 1400px)": {
      height: "270px",
      width: "100%",
    },
  },
  personDetails: {
    "@media (max-width: 300px)": {
      padding: "0 10px",
      minHeight: "50px",
    },
  },
});

function Testimonials() {
  const { t, i18n } = useTranslation();
  const currentDirection = i18n.dir();
  const backgoroundImage =
    currentDirection === "rtl" ? rtlBackgournd : ltrBackgournd;
  const classes = useStyles();
  return (
    <header
      id="testimonials"
      className="header"
      style={{
        background: `url(${backgoroundImage}) center center/cover no-repeat`,
      }}
    >
      <div className="header-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="text-container">
                <h2 className="text-center">
                  <span className="turquoise">{t("Testimonials")}</span>
                </h2>
                <Carousel
                  indicators={false}
                  interval={4000}
                  nextIcon={<ArrowIcon style={{ fontSize: "2rem" }} />}
                  prevIcon={
                    <ArrowIcon
                      style={{ transform: "rotate(180deg)", fontSize: "2rem" }}
                    />
                  }
                >
                  <Carousel.Item className="text-center">
                    <blockquote
                      className={
                        i18n.dir() === "rtl"
                          ? classes.rtlBlockquote
                          : classes.ltrBlockquote
                      }
                    >
                      <p className={classes.testimonialsDetails}>
                        {t("Testimonials Details One")}
                      </p>
                    </blockquote>
                    <p
                      style={{
                        color: "rgba(62 77 91)",
                        fontWeight: "bold",
                        margin: 0,
                      }}
                    >
                      {t("Testimonials PersonName One")}
                    </p>
                    <p
                      className={classes.personDetails}
                      style={{ color: "rgba(62 77 91)" }}
                    >
                      {t("Testimonials PersonSchool One")}
                    </p>
                  </Carousel.Item>
                  <Carousel.Item className="text-center">
                    <blockquote
                      className={
                        i18n.dir() === "rtl"
                          ? classes.rtlBlockquote
                          : classes.ltrBlockquote
                      }
                    >
                      <p className={classes.testimonialsDetails}>
                        {t("Testimonials Details Two")}
                      </p>
                    </blockquote>
                    <p
                      style={{
                        color: "rgba(62 77 91)",
                        fontWeight: "bold",
                        margin: 0,
                      }}
                    >
                      {t("Testimonials PersonName Two")}
                    </p>
                    <p
                      className={classes.personDetails}
                      style={{ color: "rgba(62 77 91)" }}
                    >
                      {t("Testimonials PersonSchool Two")}
                    </p>
                  </Carousel.Item>
                </Carousel>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="image-container">
                <img
                  className="img-fluid"
                  src={
                    currentDirection === "rtl" ? rtlTestmonials : ltrTestmonials
                  }
                  alt="alternative"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Testimonials;
