import { makeStyles } from "@mui/styles";
import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { UserIcon } from "../../../../components/SVGs";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import useValidation from "../../../../hooks/useValidation";
import Joi from "joi";
import {
  // associateTicketToContact,
  // creatSupportTicket,
  // searchContact,
  extendTrial,
  validateEmail,
} from "../../../../services/Demo";
import useToast from "../../../../hooks/useToast";
import { DemoContext } from "../../../../contexts/Demo";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "25px 0",
    "& *": {
      fontFamily: "jannat !important",
    },
  },
  user: {
    background: "rgb(105 123 140)",
    height: "120px",
    width: "120px",
    borderRadius: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "20px",
    "& svg": {
      width: "45px",
      height: "45px",
    },
  },
});

const errorsCodes = {
  215: "Already Registered",
  236: "userType",
  380: "Trial Registration Not Completed",
  382: "Trial Subscription Expired",
  383: "User Account Locked",
  384: "User Account Blocked",
  596: "Trial Account Approval",
  597: "Trial Account Approval",
  444: "TrialAlefNotAllowed",
  601: "Pending Trial Extension",
};

function VerifyEmail({ setActiveStep }) {
  const { dispatch, state } = useContext(DemoContext);
  const [error, setError] = useState("");
  const { validateProperty } = useValidation();
  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [accountToken, setAccountToken] = useState(null);
  const [isClicked, setIsClicked] = useState(false);

  const { t, i18n } = useTranslation();
  const customToast = useToast();
  const classes = useStyles();

  useEffect(() => {
    if (email.length) setIsValid(error.length === 0);
  }, [error]);

  const changeHandler = (event) => {
    const { target: input } = event;
    const emailLetters = new RegExp("^[a-zA-Z0-9._@]+$");

    const validationSchema = {
      email: Joi.string()
        .email({ tlds: { allow: false } })
        .required()
        .pattern(emailLetters)
        .messages({
          "string.empty": "EmailValidation",
          "any.required": "EmailValidation",
          "string.email": "EmailValidation",
          "string.pattern.base": "EmailLettersValidation",
        }),
    };
    input.value = input.value.toString().trim();
    const errorMesssage = validateProperty(input, validationSchema);
    setError(errorMesssage ?? "");
    setIsValid(error.length === 0);
    setEmail(input.value);
  };

  const extendTrialHandler = async () => {
    if (!isClicked) {
      setIsClicked(true);
      try {
        const response = await extendTrial({
          _accountToken: accountToken,
          _language: i18n.dir() === "ltr" ? "en" : "ar",
        });
        if (response) {
          if (response._statusCode === 200)
            customToast(
              t("Request Sent"),
              t("Request desc"),
              i18n.dir() === "rtl",
              "success"
            );
          else if (response._statusCode === 601)
            customToast(
              t("Request already sent"),
              t("Request desc"),
              i18n.dir() === "rtl",
              "success"
            );
        } else throw response;
      } catch (error) {
        console.log(error);
      }
      setIsClicked(false);
    }
  };

  const submitHandler = async () => {
    setIsValid(false);
    try {
      const response = await validateEmail({ _email: email });
      if (response) {
        if (response._statusCode === 200) {
          const { _verifiedToken: verifiedToken } = response._entity;
          const updateInfo = { ...state.userInfo, verifiedToken, email };
          dispatch({ type: "userInfo", payload: updateInfo });
          setActiveStep(0);
        } else if (errorsCodes[response._statusCode]) {
          const errorMessage =
            errorsCodes[response._statusCode] === "userType"
              ? response._entity._userType
              : errorsCodes[response._statusCode];
          setError(errorMessage);

          switch (response._statusCode) {
            case 380:
              {
                customToast(
                  t("Note"),
                  translateMessage(errorMessage),
                  i18n.dir() === "rtl",
                  "info"
                );
                const {
                  _verifiedToken: verifiedToken,
                  _authToken: authToken,
                  _firstName: firstName,
                  _lastName: lastName,
                  _schoolName: schoolName,
                  _mobileNumber: mobile,
                  _countryCode: callingCode,
                } = response._entity;

                const submittedInfo = {
                  firstName,
                  lastName,
                  schoolName,
                  callingCode,
                  mobile,
                  verifiedToken,
                  authToken,
                  email,
                };

                dispatch({ type: "userInfo", payload: submittedInfo });
                setActiveStep(1);
              }
              break;
            case 597:
            case 596:
              customToast(
                i18n.t("Note"),
                translateMessage(errorMessage),
                i18n.dir() === "rtl",
                "info"
              );
              break;
            case 601:
            case 382:
              setAccountToken(response._entity._accountToken);
              customToast(
                i18n.t("Note"),
                translateMessage(errorMessage),
                i18n.dir() === "rtl",
                "info"
              );
              break;
            case 215:
              customToast(
                i18n.t("Note"),
                translateMessage(errorMessage),
                i18n.dir() === "rtl",
                "info"
              );
              break;
            default:
              customToast(
                i18n.t("Error Label"),
                translateMessage(errorMessage),
                i18n.dir() === "rtl",
                "error"
              );
              break;
          }
        } else throw response;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const translateMessage = (validationCase) => {
    const env =
      process.env.REACT_APP_ENV === "prod"
        ? ""
        : "-" + process.env.REACT_APP_ENV;
    switch (validationCase) {
      case "SCHOOL_PRINCIPAL":
        return (
          <>
            {t("School Pricipal")}{" "}
            <a
              href={`https://principal${env}.abjadiyat.com/`}
              rel="noopener noreferrer"
            >
              {t("Link")}
            </a>{" "}
            {t("Login Your Account")}
          </>
        );
      case "TEACHER":
      case "SCHOOL_TEACHER":
        return (
          <>
            {t("School Teacher")}{" "}
            <a
              href={`https://teachers${env}.abjadiyat.com/`}
              rel="noopener noreferrer"
            >
              {t("Link")}
            </a>{" "}
            {t("Login Your Account")}
          </>
        );
      case "SCHOOL_LEADER":
        return (
          <>
            {t("School Leader")}{" "}
            <a
              href={`https://leader${env}.abjadiyat.com/`}
              rel="noopener noreferrer"
            >
              {t("Link")}
            </a>{" "}
            {t("Login Your Account")}
          </>
        );
      case "PARENT":
        return (
          <>
            {t("School Parent")}{" "}
            <a
              href={`https://parent${env}.abjadiyat.com/`}
              rel="noopener noreferrer"
            >
              {t("Link")}
            </a>{" "}
            {t("Login Your Account")}
          </>
        );
      case "Trial Subscription Expired":
        return (
          <div
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {t("Trial Subscription Expired")}{" "}
            <span
              onClick={extendTrialHandler}
              style={{
                color: "rgba(98 195 238)",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {t("Click the link")}
            </span>{" "}
          </div>
        );

      default:
        return t(validationCase);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.user}>
        <UserIcon />
      </div>
      <span style={{ fontSize: "20px", fontWeight: "bold" }}>{t("Trial")}</span>
      <span style={{ fontSize: "18px", paddingTop: "10px" }}>
        {t("Enter email to verify")}
      </span>
      <Input
        label={t("Email")}
        name="email"
        placeholder={t("Trial Email Placeholder")}
        onChange={changeHandler}
        errors={translateMessage(error)}
        displayErrorIcon
        style={{
          root: {
            width: "350px",
            fontSize: "20px",
            padding: "65px 0",
            maxWidth: "100%",
          },
        }}
      />
      <Button
        label={t("Verify Email")}
        styles={{ width: "259px" }}
        action={submitHandler}
        disable={!isValid}
      />
    </div>
  );
}

VerifyEmail.propTypes = {
  setActiveStep: PropTypes.func.isRequired,
};

export default VerifyEmail;
