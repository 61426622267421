import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  const scrollHandler = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollHandler();
  }, [pathname]);

  return null;
}
