import React, {useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

const useStyles = (progressState) => makeStyles({
    heading: {
        backgroundColor: "white",
        width: "100%",
        textAlign: "center",
        height: progressState > 0 ? "120px" : "100px",
        position: "fixed",
        fontWeight: "900",
        color: "rgba(77, 78, 76, 1)",
        top: progressState > 0 ? "60px" : "80px",
        paddingTop: progressState > 0 ? "45px" : "60px",
        marginBottom: "30px",
        zIndex: 3
    },
    subHeading: {
        fontSize: "16px",
        marginBottom: "8px"
    },
    mainHeading: {
        fontSize: "23px",
    },
});


function HeadingOfGuidedReading({progressState}) {
    const {t} = useTranslation();
    const classes = useStyles(progressState)();
    const [mainHeading, setMainHeading] = useState(t("Guided Reading Heading"))

    const progressStateAndMainHeadingMap = {
        0: `${t("Guided Reading Heading")}`,
        1: `${t("Guided Reading Video Heading")}`,
        2: `${t("Guided Reading Book Heading")}`,
        3: `${t("Guided Reading Quiz Heading")}`,
        4: `${t("Guided Reading Mic Heading")}`,
        5: `${t("Guided Reading Reward Heading")}`
    }

    useEffect(() => {
        setMainHeading(progressStateAndMainHeadingMap[progressState]);
    }, [progressState]);


    return (
        <div className={classes.heading} id="heading">
            {progressState > 0 && <div className={classes.subHeading}>{t("Guided Reading Heading")}</div>}
            <div className={classes.mainHeading}>{mainHeading}</div>
        </div>
    )
}

HeadingOfGuidedReading.propTypes = {
    progressState: PropTypes.number
}

export default HeadingOfGuidedReading;