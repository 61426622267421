import React from "react";
import 'react-phone-input-2/lib/style.css'
import FreeTrialForm from "../FreeTrialForm";
import {makeStyles} from "@mui/styles";

const useStyles = () => makeStyles({
    container: {
        marginTop: "150px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
    }
});

function GuidedReadingFreeTrialForm() {
    const classes = useStyles()();
    return (
        <div className={classes.container}>
        <FreeTrialForm />
        </div>
    )
}

export default GuidedReadingFreeTrialForm;