import { SvgIcon } from "@mui/material";
import React from "react";
export function Mail(props) {
  return (
    <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <circle cx="12" cy="12" r="12" fill="#3CB4E5" />
        <g transform="translate(2.4 2.4)">
          <path d="M0 0h19.2v19.2H0z" />
          <rect width="14.4" height="11.2" x="2.4" y="4" fill="#FFF" rx=".96" />
          <path
            stroke="#3CB4E5"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".96"
            d="M2.4 5.6l7.2 4.8 7.2-4.8"
          />
        </g>
      </g>
    </SvgIcon>
  );
}
export function Call(props) {
  return (
    <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <circle cx="12" cy="12" r="12" fill="#3CB4E5" />
        <path d="M2.4 2.4h19.2v19.2H2.4z" />
        <path
          fill="#FFF"
          d="M6.4 5.6h3.2l1.6 4-2 1.2a8.8 8.8 0 0 0 4 4l1.2-2 4 1.6v3.2a1.6 1.6 0 0 1-1.6 1.6 12.8 12.8 0 0 1-12-12 1.6 1.6 0 0 1 1.6-1.6"
        />
      </g>
    </SvgIcon>
  );
}

export function InstagramIcon(props) {
  return (
    <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 30">
      <g fill="none" fillRule="evenodd" transform="translate(.4)">
        <circle cx="15" cy="15" r="15" fill="rgba(60 180 229)" />
        <path
          fill="#FFF"
          d="M20.596 10.976a.972.972 0 1 1-1.944 0 .972.972 0 0 1 1.944 0M15.3 18a2.7 2.7 0 1 1 0-5.4 2.7 2.7 0 0 1 0 5.4m0-6.86a4.16 4.16 0 1 0 0 8.32 4.16 4.16 0 0 0 0-8.32m0-3.94c-2.2 0-2.476.01-3.34.049-.862.04-1.45.176-1.966.376a3.97 3.97 0 0 0-1.434.935 3.97 3.97 0 0 0-.935 1.434c-.2.515-.337 1.104-.376 1.966-.04.864-.049 1.14-.049 3.34s.01 2.476.049 3.34c.04.862.176 1.45.376 1.966.207.532.484.984.935 1.434.45.45.902.728 1.434.935.515.2 1.104.337 1.966.376.864.04 1.14.049 3.34.049s2.476-.01 3.34-.049c.862-.04 1.45-.176 1.966-.376a3.97 3.97 0 0 0 1.435-.935c.45-.45.727-.902.934-1.434.2-.515.337-1.104.376-1.966.04-.864.049-1.14.049-3.34s-.01-2.476-.049-3.34c-.04-.862-.176-1.45-.376-1.966a3.97 3.97 0 0 0-.934-1.434 3.97 3.97 0 0 0-1.435-.935c-.515-.2-1.104-.337-1.966-.376-.864-.04-1.14-.049-3.34-.049m0 1.46c2.163 0 2.419.008 3.273.047.79.036 1.219.168 1.504.279.378.146.648.322.931.605.284.284.46.554.606.932.111.285.243.714.28 1.504.038.854.047 1.11.047 3.273 0 2.163-.009 2.419-.048 3.273-.036.79-.168 1.219-.279 1.504a2.51 2.51 0 0 1-.606.932 2.51 2.51 0 0 1-.93.605c-.286.111-.715.243-1.505.28-.854.038-1.11.046-3.273.046-2.163 0-2.42-.008-3.273-.047-.79-.036-1.219-.168-1.504-.279a2.51 2.51 0 0 1-.932-.605 2.511 2.511 0 0 1-.605-.932c-.111-.285-.243-.714-.28-1.504-.038-.854-.047-1.11-.047-3.273 0-2.163.009-2.419.048-3.273.036-.79.168-1.219.279-1.504a2.51 2.51 0 0 1 .605-.932 2.51 2.51 0 0 1 .932-.605c.285-.111.714-.243 1.504-.28.854-.038 1.11-.047 3.273-.047"
        />
      </g>
    </SvgIcon>
  );
}

export function YouTubeIcon(props) {
  return (
    <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 30">
      <g fill="none" fillRule="evenodd">
        <path
          fill="rgba(60 180 229)"
          d="M30.4 15c0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15 0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15z"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M22.307 10.618c-.416-.742-.869-.878-1.79-.93-.919-.062-3.232-.088-5.235-.088-2.007 0-4.321.026-5.24.087-.918.053-1.372.189-1.792.93-.43.74-.65 2.016-.65 4.26v.008c0 2.235.22 3.52.65 4.252.42.74.873.875 1.791.938.92.053 3.234.085 5.24.085 2.004 0 4.317-.032 5.238-.084.92-.063 1.373-.197 1.79-.938.432-.733.651-2.017.651-4.252v-.005-.003c0-2.245-.219-3.52-.653-4.26zM13.36 17.76V12l4.8 2.88-4.8 2.88z"
        />
      </g>
    </SvgIcon>
  );
}
