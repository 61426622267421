import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/Button";
import {
  approveExtendTrial,
  unapproveExtendTrial,
} from "../../../../services/Demo";
import useToast from "../../../../hooks/useToast";
import PropTypes from "prop-types";
import { useParams } from "react-router";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "25px 0",
    "& *": {
      fontFamily: "jannat !important",
    },
  },
  user: {
    background: "rgb(105 123 140)",
    height: "120px",
    width: "120px",
    borderRadius: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "20px",
    "& svg": {
      width: "45px",
      height: "45px",
    },
  },
});

const errorsCodes = {
  598: "Invalid Trial Account",
  600: "Already Extended",
};

function ExtendTrial({ setActiveStep }) {
  const [days, setDays] = useState(7);
  const { accountToken } = useParams();
  const [isClicked, setIsClicked] = useState(false);

  const { t, i18n } = useTranslation();
  const customToast = useToast();
  const classes = useStyles();

  const disApproveExtensionHnadler = async () => {
    setIsClicked(true);
    try {
      const response = await unapproveExtendTrial(accountToken);
      if (response) {
        if (response._statusCode === 200) {
          customToast(
            t("Disapproved"),
            t("Trial extension request disapproved"),
            i18n.dir() === "rtl",
            "success"
          );
          setActiveStep(-1);
        } else if (errorsCodes[response._statusCode]) {
          const errorMessage = errorsCodes[response._statusCode];
          switch (response._statusCode) {
            case 600:
            case 598:
            default:
              break;
          }
          customToast(t("Note"), t(errorMessage), i18n.dir() === "rtl", "info");
        } else throw response;
      }
    } catch (error) {
      console.log(error);
    }
    setIsClicked(false);
  };

  const approveExtensionHnadler = async () => {
    setIsClicked(true);
    try {
      const response = await approveExtendTrial(accountToken, days);
      if (response) {
        if (response._statusCode === 200) {
          customToast(
            t("Trial extended"),
            t("Trial extended successfully"),
            i18n.dir() === "rtl",
            "success"
          );
          setActiveStep(-1);
        } else if (errorsCodes[response._statusCode]) {
          const errorMessage = errorsCodes[response._statusCode];
          switch (response._statusCode) {
            case 601:
              setActiveStep(-1);
              break;
            case 598:
            default:
              break;
          }
          customToast(t("Note"), t(errorMessage), i18n.dir() === "rtl", "info");
        } else throw response;
      }
    } catch (error) {
      console.log(error);
    }
    setIsClicked(false);
  };

  const handleChange = (event) => {
    if (event.target.value < 1) setDays(1);
    else if (event.target.value > 90) setDays(90);
    else setDays(event.target.value);
  };

  return (
    <div className={classes.root}>
      <span style={{ fontSize: "18px", paddingTop: "10px" }}>
        {t("Enter days for extension")}
      </span>
      <input
        type="number"
        min="1"
        max="90"
        value={days}
        onChange={handleChange}
        style={{
          padding: "20px 5px",
          margin: "20px",
          height: "38px",
          backgroundColor: "rgb(255 255 255)",
          fontSize: "16px",
          borderRadius: "8px",
          border: " 1px solid rgb(98 195 238)",
        }}
      />
      <Button
        label={t("Extend Trial")}
        styles={{ width: "259px" }}
        action={approveExtensionHnadler}
        disable={isClicked}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            padding: "30px",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          {t("or")}
        </div>
        <Button
          label={t("Disapprove trial extension")}
          styles={{ width: "259px" }}
          action={disApproveExtensionHnadler}
          disable={isClicked}
          white
        />
      </div>
    </div>
  );
}

ExtendTrial.propTypes = {
  setActiveStep: PropTypes.func.isRequired,
};

export default ExtendTrial;
