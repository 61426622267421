import React from "react";
import { SuccessIcon, CloseIcon, AlertIcon } from "../components/SVGs";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { toast } from "react-toastify";

const useToast =
  () =>
  (label, message, rtl, type = "success") =>
    toast(
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          fontFamily: "Dubai",
        }}
      >
        {type === "success" && <SuccessIcon style={{ fontSize: "1.75rem" }} />}
        {type === "error" && <AlertIcon style={{ fontSize: "1.5rem" }} />}
        {type === "info" && (
          <InfoOutlinedIcon
            style={{
              fontSize: "1.3rem",
              color: "rgb(98 195 238)",
              margin: "5px 10px 0",
            }}
          />
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: rtl ? "right" : "left",
            justifyContent: "space-between",
            flexGrow: 1,
            padding: "5px 0",
          }}
        >
          <span style={{ color: "rgb(0 0 0)", fontSize: "1rem" }}>{label}</span>
          <span
            style={{
              fontWeight: "bold",
              fontSize: "0.75rem",
              color: "rgb(81 81 81)",
            }}
          >
            {message}
          </span>
        </div>
        <CloseIcon style={{ fontSize: "1.75rem" }} />
      </div>,
      {
        bodyStyle: { textAlign: rtl ? "right" : "left", padding: 0, margin: 0 },
        style: {
          border: "1px solid rgb(194 219 241)",
          borderRadius: "8px",
          boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.19)",
          direction: rtl ? "rtl" : "ltr",
          padding: 0,
          margin: "15px 10px 0",
        },
      }
    );

export default useToast;
