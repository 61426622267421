import API from "./API";

export const createTrelloCard = async (data) => {
  return API.post(`ams/support-ticket`, data)
    .then((response) => response.data)
    .catch((error) => error.response);
};

export default {
  createTrelloCard
};
