import {loadStripe} from "@stripe/stripe-js";

let stripePromise;

const initializeStripe = async () => {
    if (!stripePromise) {
        if (process.env.REACT_APP_ENV.toLowerCase() === "prod") {
            stripePromise = await loadStripe(
                "pk_live_51J9QwNHFMRqdw9J6lbFoHv4KqzYoobi3pHerExgynpJmpeyFJ2BayESkOYfRHUz1uTKn2n5MJ5krKuSNUnYWBSjW00a9sKBEND"
            );
        }
        else {
            stripePromise = await loadStripe(
                "pk_test_51J9QwNHFMRqdw9J6AWBFpDWk2ZBHz8o2c3PKmqjQNapEg1AAQvdGDxVfKgkdRiIsT0SP3z5TEiIvJkPEfBQugpaC00FQISvhuE"
            );
        }
    }
    return stripePromise;
};
export default initializeStripe;