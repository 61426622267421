import React, { useEffect, useState } from "react";

function BackToTop() {
  const [show, setShow] = useState(false);
  let amountScrolled = 700;

  const showHandler = () => {
    setShow(window.pageYOffset > amountScrolled);
  };

  useEffect(() => {
    window.addEventListener("scroll", showHandler);
    return () => {
      window.removeEventListener("scroll", showHandler);
    };
  }, []);

  return (
    <a
      className={"back-to-top page-scroll"}
      style={{ opacity: show ? 1 : 0 }}
      onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
    />
  );
}

export default BackToTop;
