import Demo from "../../views/public/Demo";
import Home from "../../views/public/Home";
import StudentApp from "../../views/public/StudentApp/index";
import TeachersPlatform from "../../views/public/TeachersPlatform";
import RightsEnforcement from "../../views/public/ RightsEnforcement";
import Support from "../../views/public/Support";
import Thankyou from "../../views/public/ThankYou.jsx";
import Subscription from "../../components/Subscription";
import GuidedReading from "../../components/GuidedReading";
import GuidedReadingFreeTrialForm from "../../components/GuidedReadingFreeTrial/GuidedReadingFreeTrialForm";
// import SummerProgram from "../../views/public/SummerProgram";

const routes = [
  // {
  //   path: "/summer-program/register",
  //   name: "summerProgram",
  //   component: SummerProgram,
  // },

  {
    path: "/individuals-rights-enforcement",
    name: " RightsEnforcement",
    component: RightsEnforcement,
  },
  {
    path: "/demo/extend/:accountToken",
    name: "demo",
    component: Demo,
  },
  {
    path: "/demo/approve/:accountToken",
    name: "demo",
    component: Demo,
  },
  {
    path: "/demo/disapprove/:accountToken",
    name: "demo",
    component: Demo,
  },
  {
    path: "/subscription/thank-you",
    name: "Thankyou",
    component: Thankyou,
  },
  {
    path: "/subscription",
    name: "Subscription",
    component: Subscription,
  },
  {
    path: "/guided-reading/thank-you",
    name: "Thankyou",
    component: Thankyou,
  },
  {
    path: "/guided-reading/free-trial",
    name: "GuidedReadingFreeTrialForm",
    component: GuidedReadingFreeTrialForm,
  },
  {
    path: "/guided-reading",
    name: "GuidedReading",
    component: GuidedReading,
  },
  {
    path: "/verify-otp/:otp",
    name: "demo",
    component: Demo,
  },
  {
    path: "/demo/register",
    name: "demo",
    component: Demo,
  },
  {
    path: "/support",
    name: "Support",
    component: Support,
  },
  {
    path: "/thank-you",
    name: "ThankYou",
    component: Thankyou,
  },
  {
    path: "/products/students-application",
    name: "StudentApp",
    component: StudentApp,
  },
  {
    path: "/products/teachers-platform",
    name: "TeachersPlatform",
    component: TeachersPlatform,
  },
  // {
  //   path: "/student-app/privacy-policy",
  //   name: "PrivacyView",
  //   component: ,
  // },
  {
    path: "/",
    name: "Home",
    component: Home,
  },
];

export default routes;
