import React from "react";
import Content from "./sections/Content";
import Footer from "../../../components/Footer";
import DemoContextProvider from "../../../contexts/Demo";

function Support() {
  return (
    <>
      <DemoContextProvider>
        <Content />
        <Footer />
      </DemoContextProvider>
    </>
  );
}

export default Support;
