import React, {useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import 'react-phone-input-2/lib/style.css'
import SelectInput from "../SelectInput";
import {getCountryList} from "../../services/Common";
import {postFreeTrialRequest} from "../../services/GuidedReading";
import {useTranslation} from "react-i18next";
import Input from "../Input";
import {MicroSiteLeadsTrialSchema} from "../../views/public/Demo/sections/ValidationSchema";
import Joi from "joi";
import useValidation from "../../hooks/useValidation";
import Button from "../Button";

const useStyles = () => makeStyles({
    form: {
        marginBottom: "200px",
        direction: "rtl"
    },
    freeTrialForm: {
        display: "flex",
        flexDirection: "column"
    },
    formHeading: {
        fontSize: "20px",
        fontWeight: "bold",
        color: "rgba(98, 195, 238, 1)",
        textAlign: "center",
        marginBottom: "16px"
    },
    input: {
        marginTop: "20px",
        marginBottom: "20px",
        width: "100%",
        height: "38px",
        textAlign: "center",
    },
    submitButton: {
        marginTop: "20px",
        height: "39px",
        marginBottom: "20px",
        borderRadius: "5px",
        border: "none",
        alignItems: "center"
    },
    countryCode: {
        "width": "100px",
        marginTop: "20px"
    },
    phoneNumber: {
        display: "flex",
        direction: "rtl"
    },
    number: {
        marginTop: "23px",
        marginBottom: "20px",
        width: "290px"
    }
});

function FreeTrialForm() {
    const classes = useStyles()();
    const [formData, setFormData] = useState({teacherName: undefined, email: "", mobile: "", callingCode: "+971"});
    const [errors, setErrors] = useState({});
    const [isValid, setIsValid] = useState(false);
    const {t} = useTranslation();
    const {validateProperty} = useValidation();
    const [countryCodes, setCountryCodes] = useState([]);
    const [selectedCountryCode, setSelectedCountryCode] = useState("+971");

    const formVars = {
        teacherName: {
            name: "teacherName",
            placeholder: t("Guided Reading Teacher Name"),
        },
        email: {
            placeholder: t("Guided Reading Teacher Email"),
            name: "email"
        },
        mobile: {
            name: "mobile",
            placeholder: t("Guided Reading Teacher Phone Number"),
        },
        callingCode: {
            name: "callingCode",
            defaultValue: "+971",
            valueList: [],
        },
    };

    const validate = (schema) => {
        const options = {abortEarly: false};
        const {error} = Joi.object(schema).validate(formData, options);
        let errorsObject = {...errors};
        if (error)
            for (let item of error.details) errorsObject[item.path[0]] = item.message;
        return errorsObject;
    };

    useEffect(() => {
        setIsValid(Object.keys(errors).length === 0);
    }, [errors, isValid]);

    useEffect(() => {
        const updateErrors = validate(MicroSiteLeadsTrialSchema);
        setIsValid(Object.keys(updateErrors).length === 0);
    }, [formData, isValid]);

    const changeHandler = (event) => {
        const data = {...formData};
        const {target: input} = event;
        const errorsObject = {...errors};
        const errorMesssage = validateProperty(input, MicroSiteLeadsTrialSchema);

        if (errorMesssage) errorsObject[input.name] = errorMesssage;
        else delete errorsObject[input.name];
        setErrors(errorsObject);
        data[input.name] = input.value;
        setFormData(data);
    };

    useEffect(() => {
        setCountryList();
    }, []);

    const setCountryList = async () => {
        let codeList = [];
        try {
            const response = await getCountryList();
            if (response._statusCode === 200) {
                const {_entity: data} = response;
                if (data.length > 0) {
                    codeList = data.map((country) => country._callingCode);
                }
                codeList.sort((a, b) => (a > b ? 1 : -1));
                codeList = [...new Set(codeList)];
                codeList = codeList.map((code) => ({
                    value: code,
                    label: (
                        <span style={{
                            direction: "ltr",
                            display: "block",
                            whiteSpace: "nowrap",
                        }}>
                            {code}
            </span>),
                }));
                setCountryCodes(codeList);
            } else throw response;
        } catch (error) {
            console.log(error);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (isValid) {
            try {
                const data = {
                    "_teacherName": formData.teacherName.trim(),
                    "_email": formData.email.trim(),
                    "_phoneNumber": selectedCountryCode + formData.mobile.trim()
                };
                const response = await postFreeTrialRequest(data);
                if (response._statusCode === 200) {
                    window.location.replace(`${window.location.origin}/guided-reading/thank-you`);
                }
            } catch (error) {
                console.log(error);
            }
        }
        setIsValid(false);
    }

    const handleCountryCodeChange = (event) => {
        const {currentTarget: input} = event;
        setSelectedCountryCode(input.value.toString().trim());
    }

    return (
        <div className={classes.form}>
            <div className={classes.formHeading}>{t("Guided Reading Free Trial Form")}</div>
            <div className={classes.freeTrialForm}>
                <div className={classes.input}>
                    <Input
                        {...formVars.teacherName}
                        errors={t(errors.teacherName)}
                        onChange={changeHandler}
                        autoFocus
                    /></div>
                <div className={classes.input}>
                    <Input
                        {...formVars.email}
                        errors={t(errors.email)}
                        onChange={changeHandler}
                        autoFocus
                    /></div>
                <div className={classes.phoneNumber}>
                    <div className={classes.number} style={{width: "70%"}}><Input
                        {...formVars.mobile}
                        errors={t(errors.mobile)}
                        onChange={changeHandler}
                        autoFocus
                    /></div>
                    <div className={classes.countryCode}>
                        <SelectInput
                            name="callingCode"
                            valueList={countryCodes}
                            defaultValue={"+971"}
                            getOption={handleCountryCodeChange}
                        /></div>
                </div>
                <div className={classes.submitButton}>
                    <Button
                        label={t("Guided Reading Submit Button")}
                        disable={!isValid}
                        action={handleSubmit}
                    />
                </div>
            </div>
        </div>
    )
}

export default FreeTrialForm;