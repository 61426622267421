import React, { useEffect, useRef } from "react";
// import contactus from "../../../assets/images/contactus.jpg";

function RightsEnforcement() {
  const formRef = useRef();
  useEffect(() => {
    let script = document.createElement("script");
    script.src = `https://js.hsforms.net/forms/shell.js`;
    script.async = true;
    document.body.appendChild(script);
    setTimeout(() => {
      renderForm();
    }, 700);
  }, []);
  const renderForm = () => {
    let form = document.createElement("script");
    form.text = ` window.hbspt.forms.create({
    region: "na1",
    portalId: "20413150",
    formId: "757ab066-5320-4204-b745-37d2f6f137c3"
  });`;
    form.async = true;
    formRef.current.append(form);
  };

  return (
    <>
      <div
        style={{
          marginTop: "100px",
          textAlign: "center",
          padding: "20px 30px",
          // background: `center / contain no-repeat url(${contactus})`,
        }}
        ref={formRef}
      ></div>
    </>
  );
}

export default RightsEnforcement;
