import getStripe from "./initializeStripe";
import firebase from "../contexts/FireBase/analytics";

export async function createOrderAndSession(planType, cardId, studentId) {
    const userCredentials = await firebase
        .auth()
        .signInAnonymously();

    const firestore = firebase.firestore();
    const studentTable = process.env.REACT_APP_ENV.toLowerCase() === "prod" ? "students_data_prod" : "students_data"
    const monthlyPrice = process.env.REACT_APP_ENV.toLowerCase() === "prod" ? "price_1LqB8bHFMRqdw9J6cBMGS8yc" : "price_1LtGZEHFMRqdw9J6jTYDT94j"
    const yearlyPrice = process.env.REACT_APP_ENV.toLowerCase() === "prod" ? "price_1LubE6HFMRqdw9J6b5b87odz" : "price_1LtXFrHFMRqdw9J6vaiB09eA"
    const price = planType === "Yearly" ? yearlyPrice : monthlyPrice;
    const userId = userCredentials.user.uid;

    await firebase.firestore().collection(studentTable).doc(userId).set({
        uid: userId,
        studentId: studentId,
        provider: 'anonymous',
        cardId: cardId
    });

    try {
        const checkoutSessionRef = await firestore
            .collection(studentTable)
            .doc(userId)
            .collection("checkout_sessions")
            .add({
                price: price,
                success_url: `${window.location.origin}/subscription/thank-you`,
                cancel_url: `${window.history.state.prevUrl}`,
                studentId: userId
            });

        checkoutSessionRef.onSnapshot(async (snap) => {
            const {sessionId} = snap.data();
            if (sessionId) {
                await firebase.auth().signOut();
                const stripe = await getStripe();
                stripe.redirectToCheckout({sessionId});
            }
        });
    } catch (e) {
        console.error(e)
    }
}
