import { ApmRoute } from "@elastic/apm-rum-react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import apm from "./apm";
import "./assets/css/styles.css";
import TagManager from "./components/GoogleTagManager";
import ScrollToTop from "./components/ScrollToTop";
import FireBaseContextProvider from "./contexts/FireBase";
import "./i18n";
import reportWebVitals from "./reportWebVitals";
import routes from "./routes";

TagManager.init();

apm.setInitialPageLoadName("Home Page");

const newtheme = createTheme({
  palette: {
    primary: {
      main: "rgb(98 195 238)",
    },
    secondary: {
      main: "rgb(255 255 255)",
    },
  },
  typography: {
    fontFamily: "Jannat, Dubai",
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ToastContainer position="top-center" closeButton={false} />
    <BrowserRouter>
      <FireBaseContextProvider>
        <ScrollToTop />
        <ThemeProvider theme={newtheme}>
          <Switch>
            {routes.map((route, index) => (
              <ApmRoute
                path={route.path}
                component={route.component}
                key={index}
              />
            ))}
          </Switch>
        </ThemeProvider>
      </FireBaseContextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
