import React, { useContext } from "react";
import { InstagramIcon, YouTubeIcon } from "../../SVGs";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { FireBaseContext } from "../../../contexts/FireBase";
import { Call, Mail } from "../../SVGs/Footer";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    userSelect: "none",
    color: "rgba(60 61 66)",
    "& svg": {
      cursor: "pointer",
      margin: "0.5rem 0.5rem",
      fontSize: "1.5rem",
      "@media screen and (max-width: 768px)": {
        fontSize: "1.8rem",
      },
    },
    "& span": {
      margin: "0.5rem 1rem",
    },
    "@media screen and (max-width: 767px)": {
      flexDirection: "column",
    },
  },
  hide: {
    "@media screen and  (max-width: 767px)": {
      display: "none",
    },
  },
  contactUs: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    justifyContent: "center",
    "& span": { margin: "0 5px" },
    "@media (max-width:1024px)": {
      "& .tabletHide": {
        display: "none",
      },
    },
  },
});

function Copyright() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { logEvent } = useContext(FireBaseContext);

  return (
    <div className="copyright">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="p-small" style={{ paddingTop: "0.5rem" }}>
              <div className={classes.root}>
                <span>{t("Copyright")}</span>
                {pathname.includes("support") ? (
                  <div className={classes.contactUs}>
                    <span>{t("Cotactus")}</span>
                    <a
                      href={`mailto:helpdesk@alefeducation.com`}
                      rel="noopener noreferrer"
                    >
                      <Mail
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      />
                    </a>
                    <span className="tabletHide">
                      helpdesk@alefeducation.com
                    </span>
                    <span>{t("or by phone")}</span>
                    <a href={`tel:+97122040101`} rel="noopener noreferrer">
                      <Call sx={{ display: "flex", alignItems: "center" }} />
                    </a>
                    <span style={{ direction: "ltr" }} className="tabletHide">
                      +97122040101
                    </span>
                  </div>
                ) : null}
                <div>
                  <span className={classes.hide}>{t("Follow us")}</span>
                  <a
                    onClick={() => logEvent("ClickOnYoutype")}
                    href="https://www.youtube.com/c/AbjadiyatApp"
                    rel="noopener noreferrer"
                  >
                    <YouTubeIcon />
                  </a>
                  <a
                    onClick={() => logEvent("ClickOnInstegram")}
                    href="https://www.instagram.com/abjadiyatapp/"
                    rel="noopener noreferrer"
                  >
                    <InstagramIcon />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Copyright;
