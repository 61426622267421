import React from "react";
import { useTranslation } from "react-i18next";
import rtlDoodle from "../../../../assets/images/rtl_doodle_grey.png";
import rtlBlueCloud from "../../../../assets/images/rtl_blue_cloud.png";
import ltrDoodle from "../../../../assets/images/ltr_doodle_grey.png";
import ltrBlueCloud from "../../../../assets/images/ltr_blue_cloud.png";
import rtlStudentDevices from "../../../../assets/images/rtl_student_devices_mockup.png";
import ltrStudentDevices from "../../../../assets/images/ltr_student_devices_mockup.png";
import { makeStyles } from "@mui/styles";

const useStyles = (dir) =>
  makeStyles({
    studnetHeader: {
      display: "flex",
    },
    studentDevices: {
      marginTop: "60px",
      zIndex: 2,
      width: "50%",
      height: "50%",
      ...(dir === "rtl" ? { right: 0 } : { left: 0 }),
      "@media screen and (max-width: 767px)": {
        width: "95%",
        height: "95%",
      },
    },
    doodle: {
      position: "absolute",
      top: "5rem",
      width: "20%",
      ...(dir === "ltr" ? { right: 0 } : { left: 0 }),
      "@media screen and (max-width: 991px)": {
        top: "7rem",
      },
      "@media screen and (max-width: 767px)": {
        display: "none",
      },
    },
    blueCloud: {
      position: "absolute",
      width: "45%",
      ...(dir === "rtl" ? { right: 0 } : { left: 0 }),
      "@media screen and (max-width: 767px)": {
        display: "none",
      },
    },
  });

function Header() {
  const { i18n } = useTranslation();
  const currentDirection = i18n.dir();
  const classes = useStyles(currentDirection)();
  return (
    <header className={classes.studnetHeader}>
      <img
        className={classes.studentDevices}
        src={currentDirection === "rtl" ? rtlStudentDevices : ltrStudentDevices}
        alt="Student Devices image"
      />

      <img
        className={classes.doodle}
        src={currentDirection === "rtl" ? rtlDoodle : ltrDoodle}
        alt="doodle image"
      />
      <img
        className={classes.blueCloud}
        src={currentDirection === "rtl" ? rtlBlueCloud : ltrBlueCloud}
        alt="blue cloud image"
      />
    </header>
  );
}

export default Header;
