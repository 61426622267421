import React from "react";
import { SmallLogoIcon, LocationIcon, EmailIcon, PhoneIcon } from "../SVGs";
import { useTranslation } from "react-i18next";
import AlefLogo from "../../assets/images/alef_logo.png";
import Copyright from "./Copyright";
import { makeStyles } from "@mui/styles";
import { useHistory, useLocation } from "react-router";

const useStyles = makeStyles({
  about: {
    display: "flex",
    alignItems: "flex-start",
    "& svg": {
      fontSize: "2rem",
    },
    "& h4": {
      margin: "0 0.5rem 1rem",
    },
  },
  aboutDetials: {
    padding: "0 1rem",
    width: "90%",
    "& *": {
      fontSize: "0.8rem",
      margin: 0,
    },
    "& p:last-child": {
      marginBottom: "16px",
    },
  },
  contactus: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    "& div": {
      marginBottom: "0.75rem",
    },
    "& *": {
      fontSize: "0.8rem",
    },
    "& svg": {
      fontSize: "1rem",
      margin: "0 0.25rem",
    },
  },
});
const LocationOnMap = "https://goo.gl/maps/2qaAt3aUKDZTMPBN6";
function Footer() {
  const history = useHistory();
  const classes = useStyles();
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();
  return (
    <>
      {!pathname.includes("support") ? (
        <div className="footer">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div
                  className="footer-col first"
                  style={{
                    textAlign: i18n.dir() === "rtl" ? "right" : "left",
                    marginBottom: 0,
                  }}
                >
                  <div
                    className={classes.about}
                    style={{
                      justifyContent: i18n.dir() === "rtl" ? "right" : "left",
                      marginBottom: 0,
                    }}
                  >
                    <SmallLogoIcon />
                    <h4>{t("About Abjadiyat")}</h4>
                  </div>
                  <div className={classes.aboutDetials}>
                    <p>{t("About Abjadiyat Details")}</p>
                    <p style={{ whiteSpace: "nowrap" }}>
                      <span style={{ color: "rgb(60 61 66)" }}>
                        {`${t("Read")} `}
                      </span>
                      <a
                        href="https://legal.abjadiyat.com/Abjadiyat_Website_Terms_of_Use_DUAL.pdf"
                        rel="noopener noreferrer"
                      >
                        {t("Terms Conditions")}
                      </a>
                    </p>
                    <p style={{ whiteSpace: "nowrap" }}>
                      <span style={{ color: "rgb(60 61 66)" }}>
                        {`${t("ReadPolicy")} `}
                      </span>
                      <a
                        href={
                          i18n.language === "ar"
                            ? "https://legal.abjadiyat.com/Abjadiyat_Privacy_Notice_ar.pdf"
                            : "https://legal.abjadiyat.com/Abjadiyat_Privacy_Notice_en.pdf"
                        }
                        rel="noopener noreferrer"
                      >
                        {t("Policy")}
                      </a>
                    </p>
                    <p style={{ whiteSpace: "nowrap" }}>
                      <span style={{ color: "rgb(60 61 66)" }}>
                        {`${t("Apply")} `}
                      </span>
                      <span
                        style={{
                          color: "rgba(60 180 229)",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          history.push("/individuals-rights-enforcement")
                        }
                      >
                        {t("RightsEnforcement")}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="footer-col middle">
                  <img src={AlefLogo} alt="Alef education logo" />
                </div>
              </div>
              <div
                className="col-md-5"
                style={{ textAlign: i18n.dir() === "rtl" ? "right" : "left" }}
              >
                <div
                  className="footer-col last"
                  style={{
                    margin: i18n.dir() === "rtl" ? "0 auto 0 0" : "0 0 0 auto",
                    display: "flex",
                  }}
                >
                  <div style={{ flexGrow: 1 }}></div>
                  <div>
                    <h4>{t("Contact us")}</h4>
                    <div className={classes.contactus}>
                      <div>
                        <a href={LocationOnMap} rel="noopener noreferrer">
                          <LocationIcon />
                          <span>{t("ourLocation")}</span>
                        </a>
                      </div>
                      <div>
                        <a
                          href={`mailto:${t("ourEmail")}`}
                          rel="noopener noreferrer"
                        >
                          <EmailIcon />
                          <span>{t("ourEmail")}</span>
                        </a>
                      </div>
                      <div>
                        <a
                          href={`tel:${t("ourPhone")}`}
                          rel="noopener noreferrer"
                        >
                          <PhoneIcon />
                          <span>{t("ourPhone")}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <Copyright />
    </>
  );
}

export default Footer;
