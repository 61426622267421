import React, { useContext } from "react";
import {
  Player,
  ControlBar,
  CurrentTimeDisplay,
  TimeDivider,
  PlaybackRateMenuButton,
  VolumeMenuButton,
  BigPlayButton,
  LoadingSpinner,
} from "video-react";
import "video-react/dist/video-react.css";
import { FireBaseContext } from "../../contexts/FireBase";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

const useStyles = (url) =>
  makeStyles({
    root: {
      direction: "ltr",
      position: "relative",
      "& > div": {
        borderRadius: "0.75rem",
        width: "52.5rem !important",
        height: "29rem !important",
        "&  video": {
          borderRadius: "0.7rem",
          border: "0.25rem solid rgb(98 195 238)",
        },
        "& > div": {
          borderRadius: "0.7rem",
          border: "0.15rem solid rgb(98 195 238)",
        },
        paddingTop: "0 !important",
      },
      "& .video-react-poster": {
        background: `url('${url}') center center/cover no-repeat !important`,
      },
      "& .video-react-big-play-button": {
        height: "3.5rem !important",
        width: "3.5rem !important",
        borderRadius: "50% !important",
        " &:before": {
          top: "10% !important",
        },
      },
      "& .video-react-big-play-button.video-react-big-play-button-center": {
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        margin: "auto",
      },
      "& .video-react-bezel .video-react-bezel-icon": {
        margin: "6px",
      },
      "@media screen and  (max-width: 992px)": {
        " & > div": {
          height: "400px !important",
        },
      },
      "@media screen and  (max-width: 767px)": {
        " & > div": {
          height: "250px !important",
        },
      },
    },
  });

const VideoPlayer = (props) => {
  const classes = useStyles(props.poster)();
  const { logEvent } = useContext(FireBaseContext);
  const handleVideoPlay = () => {
    logEvent("PlayDiscoverAbjadiyatVideo");
  };
  const { currentVideo, poster } = props;
  return (
    <div className={classes.root}>
      <Player src={currentVideo} onPlay={handleVideoPlay} poster={poster}>
        <BigPlayButton position="center" />
        <LoadingSpinner />
        <ControlBar>
          <CurrentTimeDisplay order={4.1} />
          <TimeDivider order={4.2} />
          <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
          <VolumeMenuButton order={6.1} />
        </ControlBar>
      </Player>
    </div>
  );
};
VideoPlayer.propTypes = {
  currentVideo: PropTypes.string.isRequired,
  poster: PropTypes.string,
};
export default VideoPlayer;
