import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import useLocale from "../../hooks/useLocale";
import { FireBaseContext } from "../../contexts/FireBase";

const useStyles = makeStyles({
  languageSwitch: {
    borderRadius: "50%",
    height: "100%",
    width: "100%",
    color: "rgb(255 255 255)",
    textAlign: "center",
    fontSize: "0.5rem",
    cursor: "pointer",
    border: "1px solid",
    "@media screen and  (max-width: 992px)": {
      color: "rgb(60 180 229)",
    },
  },
  arText: {
    lineHeight: "1.1rem",
    fontFamily: "Geeza Pro",
    fontSize: "0.8rem",
    "@media screen and  (max-width: 992px)": {
      lineHeight: "1.4rem",
    },
  },
});

function LanguageSwitch() {
  const { dir } = useLocale();
  const [lang, setLang] = useState("en");
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { logEvent } = useContext(FireBaseContext);

  const handleClick = (event) => {
    const newLang = event.target.innerText === t("en") ? "ar" : "en";
    setLang(newLang);
    const isArabicLang = newLang === "ar";
    i18n.changeLanguage(isArabicLang ? "en" : "ar");
    localStorage.setItem("dir", isArabicLang ? "ltr" : "rtl");
    logEvent("ChangeLanguage");
  };

  useEffect(() => {
    const isRtlDir = dir === "rtl";
    setLang(isRtlDir ? "en" : "ar");
    i18n.changeLanguage(isRtlDir ? "ar" : "en");
  }, []);

  return (
    <div
      className={classNames(
        classes.languageSwitch,
        lang === "ar" && classes.arText
      )}
      onClick={handleClick}
    >
      <span>{t(lang)}</span>
    </div>
  );
}

export default LanguageSwitch;
