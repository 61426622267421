import Joi from "joi";
const numberPattern = new RegExp("^[0-9]*$");

const SupportSchema = {
  mobile: Joi.string()
    .required()
    .min(8)
    .max(15)
    .pattern(numberPattern)
    .messages({
      "string.pattern.base": "mobileValidation",
      "string.empty": "Mobile is required",
      "string.max": "mobileValidation",
      "string.min": "mobileValidation",
    }),
  callingCode: Joi.any(),
  email: Joi.string()
    .required()
    .email({ tlds: { allow: false } })
    .messages({
      "string.empty": "Email Id is required",
      "string.email": "EmailValidation",
      "string.pattern.base": "EmailLettersValidation",
    }),
  issueDescription: Joi.string().required().messages({
    "any.required": "issue description is required",
  }),
  acceptAgreement: Joi.valid(false).required().messages({
    "any.only": "acceptAgreement",
  }),
};

export default SupportSchema;
