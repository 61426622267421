import React from "react";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@mui/styles";

const useStyles = (dir) =>
    makeStyles({
        float: {
            position: "fixed",
            zIndex: "999",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            ...(dir === "rtl" ? {right: "0.75rem"} : {left: "0.75rem"}),
            bottom: "3rem",
        },
        "@keyframes pulse-orange": {
            "0%": {
                transform: "scale(0.95)",
                boxShadow: "0 0 0 0 rgba(228, 125, 0, 0.7)",
            },
            "70%": {
                transform: "scale(1)",
                boxShadow: "0 0 0 15px rgba(228, 125, 0, 0)",
            },

            "100% ": {
                transform: "scale(0.95)",
                boxShadow: "0 0 0 5px rgba(228, 125, 0, 0)",
            },
        },
        bookClass: {
            background: "rgb(228 125 0)",
            color: "rgb(255 255 255)",
            cursor: "pointer",
            textDecoration: "none",
            padding: "0.5rem 1.25rem",
            borderRadius: "33px",
            margin: "0 10px",
            width: "142px",
            position: "fixed",
            zIndex: "999",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            animation: "$pulse-orange 1.1s infinite",
            ...(dir === "rtl" ? {right: "3.75rem"} : {left: "3.75rem"}),
            bottom: "3rem",
            stroke: "currentColor",
            "&:hover": {
                background: "rgba(228 125 0/90%)",
                animation: "none",
            },
        },
    });


function FreeTrial() {
    const {i18n, t} = useTranslation();
    const classes = useStyles(i18n.dir())();

    return (
        <a className={classes.bookClass} href={`${window.location.origin}/guided-reading/free-trial`}
             style={{opacity: 1, left: "10px",bottom: "100px"}}
             id="custome-pulse"
        >
            <span style={{margin: "0 10px", fontWeight: "bold"}}>
          {t("Guided Reading Free Trial Button")}
        </span>
        </a>
    );
}


export default FreeTrial;
