import React from "react";
import { withStyles, makeStyles } from "@mui/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector from "@mui/material/StepConnector";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const QontoConnector = withStyles({
  root: {
    marginTop: "30px",
  },
  active: {
    "& $line": {
      border: "1px solid rgba(98, 195, 238, 1)",
    },
  },
  completed: {
    "& $line": {
      border: "1px solid rgba(98, 195, 238, 1)",
    },
  },
  line: {
    borderTopStyle: "none !important",
    border: "1px dashed rgb(213 213 213)",
  },
})(StepConnector);

const useStyles = () =>
  makeStyles({
    root: {
      width: "100%",
    },
    stepper: {
      "@media screen and (max-width: 991px)": {
        background: "transparent",
      },
    },
    step: {
      padding: "0 !important",
    },
    stepLabelRoot: {
      flexDirection: "column-reverse",
      position: "relative",
      textAlign: "center",
      paddingTop: "30px",
    },
    labelContainer: {
      position: "absolute",
      top: 0,
      margin: "auto",
      minWidth: "100px",
    },
    label: {
      fontSize: "14px !important",
      fontFamily: "Jannat !important",
      color: "rgb(105 123 140) !important",
      whiteSpace: "nowrap",
    },
    iconContainer: {
      paddingRight: "0 !important",
    },
    stepIcon: {
      color: "rgba(255, 255, 255, 1) !important",
      border: "3px solid rgb(98 195 238)",
      width: "39px !important",
      height: "39px !important",
      borderRadius: "39px",
      marginTop: "4px",
      "& [class*='MuiStepIcon-text']": {
        fill: "rgb(105 123 140)",
      },
      "@media screen and  (max-width: 767px)": {
        width: "30px !important",
        height: "30px !important",
      },
    },
    activeStep: {
      border: 0,
      color: "rgb(98 195 238) !important",
      "& [class*='MuiStepIcon-text']": {
        fill: "rgba(255 255 255)",
      },
    },
    stepNumber: {
      fill: "rgba(105, 123, 140, 1)",
      fontSize: "0.75rem",
      "@media screen and  (max-width: 767px)": {
        fontSize: "0.6rem",
      },
    },
  });

function HorizontalStepper({ activeStep }) {
  const { t, i18n } = useTranslation();
  const currentDirection = i18n.dir();
  const classes = useStyles(currentDirection)();
  const steps = [t("Register"), t("Verification"), t("Completed")];

  return (
    <div className={classes.root}>
      <Stepper
        classes={{ root: classes.stepper }}
        activeStep={activeStep}
        connector={<QontoConnector />}
      >
        {steps.map((label) => (
          <Step key={label} classes={{ root: classes.step }}>
            <StepLabel
              classes={{
                root: classes.stepLabelRoot,
                labelContainer: classes.labelContainer,
                iconContainer: classes.iconContainer,
                label: classes.label,
                active: classes.activeLabel,
              }}
              StepIconProps={{
                classes: {
                  root: classes.stepIcon,
                  text: classes.stepNumber,
                  active: classes.activeStep,
                  completed: classes.activeStep,
                },
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}

HorizontalStepper.propTypes = {
  activeStep: PropTypes.number,
};

export default HorizontalStepper;
