import Joi from "joi";

const useValidation = () => {
  const validateProperty = ({ name, value }, schema) => {
    const obj = { [name]: value };
    const propertySchema = {
      [name]: schema[name],
    };
    const { error } = Joi.object(propertySchema).validate(obj);
    return error ? error.details[0].message : null;
  };

  return {
    validateProperty,
  };
};
export default useValidation;
