import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import rtlDoodle from "../../../../assets/images/rtl_doodle_grey.png";
import rtlBlueCloud from "../../../../assets/images/rtl_blue_cloud.png";
import ltrDoodle from "../../../../assets/images/ltr_doodle_grey.png";
import ltrBlueCloud from "../../../../assets/images/ltr_blue_cloud.png";
import Lessons from "../../../../assets/images/abjadiyat_lessons.png";
import { makeStyles } from "@mui/styles";


const useStyles = (dir) =>
  makeStyles({
    studnetHeader: {
      height: "700px",
      "@media screen and (max-width: 991px)": {
        marginBottom: "5rem",
      },
      "@media screen and (max-width: 767px)": {
        marginBottom: "3rem",
        height: "450px",
      },
      "@media (min-width: 1400px)": {
        height: "850px",
      },
    },
    studentDevices: {
      width: "50%",
      "@media screen and (max-width: 991px)": {
        width: "75%",
      },
      "@media screen and (max-width: 767px)": {
        width: "85%",
        top: "70px",
      },
    },
    doodle: {
      position: "absolute",
      top: "5rem",
      width: "20%",
      ...(dir === "ltr" ? { right: 0 } : { left: 0 }),
      "@media screen and (max-width: 991px)": {
        top: "7rem",
      },
      "@media screen and (max-width: 767px)": {
        display: "none",
      },
    },
    blueCloud: {
      position: "absolute",
      width: "50%",
      ...(dir === "rtl" ? { right: 0 } : { left: 0 }),
      "@media screen and (max-width: 767px)": {
        display: "none",
      },
      "@media screen and (max-width: 991px)": {
        width: "45%",
      },
    },
    headerText: {
      height: "100%",
      width: "100%",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      justifyContent: "center",
      flexDirection: "column",
      position: "relative",
      "& > h2": {
        marginTop: "2rem",
      },
      "& > p": {
        ...(dir === "rtl"
          ? { width: "40%", marginBottom: "2rem" }
          : { width: "40%", marginBottom: "0rem" }),
      },
      "@media screen and (max-width: 991px)": {
        "& > p": {
          ...(dir === "rtl" ? { width: "80%" } : { width: "70%" }),
        },
      },
      "@media screen and (max-width: 767px)": {
        marginTop: "5rem",
        "& > h2": {
          fontSize: "1.75rem",
        },
        "& > p": {
          fontSize: "0.95rem",
          width: "90%",
        },
      },
    },
  });

function Header() {
  const { t, i18n } = useTranslation();
  const studentDevicesRef = useRef(null);
  const currentDirection = i18n.dir();

  const classes = useStyles(currentDirection)();
  return (
    <header className={classes.studnetHeader}>
      <img
        className={classes.doodle}
        src={currentDirection === "rtl" ? rtlDoodle : ltrDoodle}
        alt="doodle image"
        ref={studentDevicesRef}
      />
      <img
        className={classes.blueCloud}
        src={currentDirection === "rtl" ? rtlBlueCloud : ltrBlueCloud}
        alt="blue cloud image"
      />
      <div className={classes.headerText}>
        <img
          className={classes.studentDevices}
          src={Lessons}
          alt="Student Devices image"
        />
        <h2>{t("Abjadiyat Lessons")}</h2>
        <p>{t("Abjadiyat Lessons Details")}</p>
      </div>
    </header>
  );
}

export default Header;
