import React, { useState, useContext, useEffect } from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Input from "../../../../components/Input";
import TextArea from "../../../../components/TextArea";
import support from "../../../../assets/images/support.png";
import SelectInput from "../../../../components/SelectInput";
import Button from "../../../../components/Button";
import { DemoContext } from "../../../../contexts/Demo";
import Joi from "joi";
import SupportSchema from "./ValidationSchema";
import { useLocation, useHistory } from "react-router-dom";
import qs from "qs";
import useValidation from "../../../../hooks/useValidation";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { createTrelloCard } from "../../../../services/Support";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
    "& *": {
      fontSize: "14px",
    },
  },
});

const errorTypes = {
  388: "EmailValidation",
};

const arbToEng = {
  "\u0660": 0,
  "\u0661": 1,
  "\u0662": 2,
  "\u0663": 3,
  "\u0664": 4,
  "\u0665": 5,
  "\u0666": 6,
  "\u0667": 7,
  "\u0668": 8,
  "\u0669": 9,
};

function Content() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const {
    state: { userInfo, callingCodes },
  } = useContext(DemoContext);
  const [formData, setFormData] = useState(userInfo);
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);
  const { validateProperty } = useValidation();

  useEffect(() => {
    setIsValid(Object.keys(errors).length === 0);
  }, [errors]);

  useEffect(() => {
    const updateErrors = validate(SupportSchema);
    setIsValid(Object.keys(updateErrors).length === 0);
  }, [formData]);

  const changeHandler = (event) => {
    const data = { ...formData };
    const { target: input } = event;
    const errorsObject = { ...errors };
    if ("mobile" === input.name) {
      input.value = input.value.toString().trim();
      Object.keys(arbToEng).forEach((num) => {
        if (input.value.includes(num)) {
          input.value = input.value.replace(num, arbToEng[num]);
        }
      });
    }

    const errorMesssage = validateProperty(input, SupportSchema);

    if (errorMesssage) errorsObject[input.name] = errorMesssage;
    else delete errorsObject[input.name];
    setErrors(errorsObject);
    setIsValid(Object.keys(errorsObject).length === 0);
    data[input.name] = input.value;
    setFormData(data);
  };

  const callingCodeChangeHandler = (event) => {
    setFormData({ ...formData, callingCode: event.currentTarget.value });
  };

  const validate = (schema) => {
    const options = { abortEarly: false };
    const { error } = Joi.object(schema).validate(formData, options);
    let errorsObject = { ...errors };
    if (error)
      for (let item of error.details) errorsObject[item.path[0]] = item.message;
    return errorsObject;
  };

  const formVars = {
    email: {
      name: "email",
      label: t("Email"),
      defaultValue: userInfo.email,
    },
    mobile: {
      name: "mobile",
      placeholder: t("Mobile Placeholder"),
      defaultValue: userInfo.mobile,
    },
    callingCode: {
      name: "callingCode",
      defaultValue: userInfo.callingCode,
      valueList: callingCodes,
    },
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!isValid) return;
    const encodedstr = qs.parse(decodeURI(location.search), {
      ignoreQueryPrefix: true,
    }).params;
    const decodedStr = atob(encodedstr);
    const newParams = decodedStr.split("&");
    const studentId = newParams[0].split("=")[1];
    const payload = {
      _studentId: studentId,
      _parentEmail: formData.email,
      _parentMobile: formData.mobile
        ? formData.callingCode + formData.mobile
        : "",
      _issueDesc: formData.issueDescription,
    };

    try {
      const response = await createTrelloCard(payload);
      if (response && response._statusCode === 200) {
        history.push("/thank-you");
      } else if (response._statusCode === 306) {
        toast.error(i18n.t("Requested Student data not found"), {
          toastId: "technicalIssue",
          closeButton: true,
          style: { width: "100w" },
          bodyStyle: { textAlign: "right", fontSize: "19px" },
        });
        const errorsObject = { ...errors };
        errorsObject["email"] = errorTypes[response._statusCode];
        setErrors(errorsObject);
      } else throw response;
    } catch (error) {
      console.log(error);
      toast.error(i18n.t("technical issue"), {
        toastId: "technicalIssue",
        closeButton: true,
        style: { width: "fit-content" },
        bodyStyle: { textAlign: "right", fontSize: "19px" },
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container className={classes.root} sx={{ pt: 20 }}>
        <Grid item xs={12} sm={10} lg={7}>
          <img src={support} width="90%" />
        </Grid>
        <Grid item xs={10} sm={8} lg={5}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              lg={7}
              style={{
                margin: "30px 20px",
                width: "100%",
                textAlign: "center",
                fontSize: "18px",
                color: "#3cb4e5",
              }}
            >
              {t(
                "Please fill up the form below, and our team will get back to you"
              )}
            </Grid>
            <Grid item xs={12} sm={12} lg={7}>
              <Grid
                container
                spacing={1}
                alignItems="flex-end"
                sx={{ paddingBottom: "5px", direction: "rtl" }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    width: "100%",
                    padding: 0,
                    margin: 0,
                    textAlign: i18n.dir() === "rtl" ? "right" : "left",
                  }}
                >
                  {t("mobileLabelwithWhatsapp")}
                </Grid>
                <Grid item xs={8}>
                  <Input
                    {...formVars.mobile}
                    onChange={changeHandler}
                    errors={t(errors.mobile)}
                    style={{ root: { width: "100%" } }}
                  />
                </Grid>
                <Grid item xs={4} sx={{ paddingRight: "5px" }}>
                  <SelectInput
                    {...formVars.callingCode}
                    getOption={callingCodeChangeHandler}
                    style={{ container: { width: "100%" } }}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item xs={12} sm={12} lg={7}
              style={{
                margin: "10px 20px",
                width: "100%",
                textAlign: "center",
                fontSize: "18px",
                color: "#3cb4e5"
              }}
            >
              {t("or")}
            </Grid> */}
            <Grid item xs={12} sm={12} lg={7} sx={{ padding: "20px 0" }}>
              <Input
                {...formVars.email}
                placeholder={t("Trial Email Placeholder")}
                onChange={changeHandler}
                errors={t(errors.email)}
              />
            </Grid>
          </Grid>
          <Grid container sx={{ padding: "20px 0" }}>
            <Grid item xs={12} sm={12} lg={7}>
              <TextArea
                name="issueDescription"
                label={t("issueDescription")}
                defaultValue={formData.text}
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
          <Grid container sx={{ padding: "20px 0" }}>
            <Grid
              item
              xs={12}
              sm={12}
              lg={7}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button label={t("submit")} disable={!isValid} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

export default Content;
