import React from "react";
import Header from "./sections/Header";
import Features from "./sections/Features";
import DiscoverAbjadiyat from "./sections/DiscoverAbjadiyat";
import Testimonials from "./sections/Testimonials";
import Footer from "../../../components/Footer";
import Partners from "./sections/Partners";

function Home() {
  return (
    <>
      <Header />
      <DiscoverAbjadiyat />
      <Features />
      <Testimonials />
      <Partners />
      <Footer />
    </>
  );
}

export default Home;
