import React from "react";
import { useTranslation } from "react-i18next";
import {
  AbjadiyatWorldIcon,
  StudentPrgressIcon,
  ArabicLessonsIcon,
  GuidedReadingIcon,
  SendTextIcon,
  VoiceRecordIcon,
} from "../../../../components/SVGs";

const features = [
  {
    title: "Arabic Lessons Feature",
    description: "Arabic Lessons Feature Details",
    icon: <ArabicLessonsIcon className="card-image" />,
  },
  {
    title: "Guided Reading Feature",
    description: "Guided Reading Feature Details",
    icon: <GuidedReadingIcon className="card-image" />,
  },
  {
    title: "Student Reports Feature",
    description: "Student Reports Feature Details",
    icon: <StudentPrgressIcon className="card-image" />,
  },
  {
    title: "Abjadiyat World Feature",
    description: "Abjadiyat World Feature Details",
    icon: <AbjadiyatWorldIcon className="card-image" />,
  },
  {
    title: "Submit Text Feature",
    description: "Submit Text Feature Details",
    icon: <SendTextIcon className="card-image" />,
  },
  {
    title: "Submit Voice Feature",
    description: "Submit Voice Feature Details",
    icon: <VoiceRecordIcon className="card-image" />,
  },
];

function Features() {
  const { t } = useTranslation();
  return (
    <div id="features" className="cards-1">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h2>{t("features")}</h2>
          </div>
        </div>
        <div className="row">
          {features.map((feature, index) => (
            <div
              key={"feature" + index}
              className="col-lg-4 col-sm-6 col-xs-12"
            >
              <div className="card">
                {feature.icon}
                <div className="card-body">
                  <h4 className="card-title">{t(feature.title)}</h4>
                  <p>{t(feature.description)}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Features;
