import API from "./API";

export const getCountryList = async () => {
  return API.get(`ams/countries`)
    .then((response) => response.data)
    .catch((error) => error.response);
};

export default {
  getCountryList,
};
