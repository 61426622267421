import API from "./API";
import axios from "axios";

export const postRegistration = async (data) => {
  return API.post("/ams/trial-teacher-registration", data)
    .then((response) => response.data)
    .catch((error) => error.response);
};

export const updateRegistration = async (data) => {
  return API.put("/ams/trial-teacher-registration", data)
    .then((response) => response.data)
    .catch((error) => error.response);
};

export const postVerifyOTP = async (data) => {
  return API.post("/ams/trial-teacher-otp-verification", data)
    .then((response) => response.data)
    .catch((error) => error.response);
};

export const postResendOTP = async (data) => {
  return API.post("/ams/trial-teacher-resend-otp", data)
    .then((response) => response.data)
    .catch((error) => error.response);
};

export const submitSalesForm = async (data) => {
  const url = `https://api.hsforms.com/submissions/v3/integration/submit/20413150/1dc2414c-29a0-4eb5-ba83-8058fb2aa759`;
  return axios
    .post(url, data)
    .then((response) => response.data)
    .catch((error) => error.response);
};

// export const creatSupportTicket = async (data) => {
//   const url = `https://api.hubapi.com/crm/v3/objects/tickets?hapikey=e51b426b-cf21-4299-a5ec-210897a254e1`;
//   return axios
//     .post(url, data)
//     .then((response) => response.data)
//     .catch((error) => error.response);
// };
// export const searchContact = async (data) => {
//   const url = `https://api.hubapi.com/crm/v3/objects/contacts/search?hapikey=e51b426b-cf21-4299-a5ec-210897a254e1`;
//   return axios
//     .post(url, data)
//     .then((response) => response.data)
//     .catch((error) => error.response);
// };
// export const associateTicketToContact = async (ticketId, contactId) => {
//   const url = `https://api.hubapi.com/crm/v3/objects/tickets/${ticketId}/associations/contact/${contactId}/ticket_to_contact?hapikey=e51b426b-cf21-4299-a5ec-210897a254e1`;
//   return axios
//     .put(url)
//     .then((response) => response.data)
//     .catch((error) => error.response);
// };

export const validateEmail = async (data) => {
  return API.post("ams/trial-validate-email", data)
    .then((response) => response.data)
    .catch((error) => error.response);
};

export const approveTrial = async (accountToken) => {
  return API.get(`/ams/trial-teacher/APPROVED?accountToken=${accountToken}`)
    .then((response) => response.data)
    .catch((error) => error.response);
};
export const approveExtendTrial = async (accountToken, days) => {
  return API.get(
    `/ams/trial-teacher/extension/APPROVED?accountToken=${accountToken}&days=${days}`
  )
    .then((response) => response.data)
    .catch((error) => error.response);
};

export const unapproveTrial = async (accountToken) => {
  return API.get(`/ams/trial-teacher/UNAPPROVED?accountToken=${accountToken}`)
    .then((response) => response.data)
    .catch((error) => error.response);
};
export const unapproveExtendTrial = async (accountToken) => {
  return API.get(
    `/ams/trial-teacher/extension/UNAPPROVED?accountToken=${accountToken}`
  )
    .then((response) => response.data)
    .catch((error) => error.response);
};

export const extendTrial = async (data) => {
  return API.post("/ams/trial-teacher/extend", data)
    .then((response) => response.data)
    .catch((error) => error.response);
};

export default {
  extendTrial,
  postRegistration,
  updateRegistration,
  postVerifyOTP,
  postResendOTP,
  validateEmail,
  approveTrial,
  unapproveTrial,
  submitSalesForm,
  // associateTicketToContact,
  // searchContact,
  // creatSupportTicket,
};
