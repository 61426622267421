import React from "react";
import { useTranslation } from "react-i18next";
import Planning from "../../../../assets/images/planning.png";
import Report from "../../../../assets/images/reports_view.png";
import RtlReport from "../../../../assets/images/rtl_reports_ipad.png";
import LtrReport from "../../../../assets/images/ltr_reports_ipad.png";
import Assign from "../../../../assets/images/assign.png";
import { makeStyles } from "@mui/styles";
import Carousel from "react-bootstrap/Carousel";
import classNames from "classnames";

const useStyles = (dir) =>
  makeStyles({
    planningSection: {
      textAlign: "right",
      "& .list": {
        paddingRight: 0,
        "& li": {
          paddingRight: "15px",
          paddingLeft: 0,
        },
        "& li:before": {
          left: "auto",
          right: 0,
        },
      },
      "& h2": {
        textAlign: "right",
      },
      "@media (min-width: 768px) and (max-width: 1024px)": {
        paddingLeft: "30px",
      },
    },

    pr_30: {
      "@media (min-width: 768px) and (max-width: 1024px)": {
        paddingRight: "30px",
      },
    },

    pl_30: {
      "@media (min-width: 768px) and (max-width: 1024px)": {
        paddingLeft: "30px",
      },
    },

    reportSection: {
      textAlign: "left",
      "& .list": {
        paddingRight: 0,
        "& li": {
          paddingRight: "15px",
        },
        "& li:before": {
          left: "auto",
          right: 0,
        },
      },
      "& h2": {
        textAlign: "right",
      },
      "@media (min-width: 768px) and (max-width: 1024px)": {
        paddingRight: "30px",
      },
    },

    imageRightAbs: {
      "@media (min-width: 768px) and (max-width: 1024px)": {
        transform: dir === "rtl" ? "translateX(-15px)" : "translateX(15px)",
      },
    },

    imageLeftAbs: {
      "@media (min-width: 768px) and (max-width: 1024px)": {
        transform: dir === "rtl" ? "translateX(30px)" : "translateX(-30px)",
      },
    },
  });

function Content() {
  const { t, i18n } = useTranslation();
  const currentDirection = i18n.dir();
  const classes = useStyles(currentDirection)();
  return (
    <section id="teachersFeatures">
      <div className="container">
        <div className="row align-items-center">
          <div
            id="carouselExampleIndicators"
            className="col-md-5 col-lg-6 carousel slide"
            data-ride="carousel"
            style={{ overflow: "hidden" }}
          >
            <Carousel
              interval={2000}
              controls={false}
              className={classes.imageLeftAbs}
            >
              <Carousel.Item>
                <img
                  className="img-fluid mb-30 w-100"
                  src={Planning}
                  alt="alternative"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid mb-30 w-100"
                  src={Assign}
                  alt="alternative"
                />
              </Carousel.Item>
            </Carousel>
          </div>
          <div
            className={classNames(
              "col-md-7 col-lg-5",
              i18n.dir() === "rtl" ? "pr-lg-5" : "pl-lg-5"
            )}
          >
            <div
              className={
                currentDirection === "rtl"
                  ? classes.planningSection
                  : classes.pr_30
              }
            >
              <h2>{t("Planning And Assigning")}</h2>
              <ul className="list">
                <li>{t("Planning Key Point One")}</li>
                <li>{t("Planning Key Point Two")}</li>
                <li>{t("Planning Key Point Three")}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-70">
        <div className="row align-items-center flex-md-row-reverse">
          <div className="col-md-5 col-lg-6">
            <img
              className="img-fluid mb-30 ipad-hide"
              src={Report}
              alt="alternative"
            />
            <div className={classNames("ipad-show", classes.imageRightAbs)}>
              <img
                className="img-fluid mb-30 w-100 ipad-show"
                src={currentDirection === "rtl" ? RtlReport : LtrReport}
                alt="alternative"
              />
            </div>
          </div>
          <div
            className={classNames(
              "col-md-7 col-lg-5",
              i18n.dir() === "rtl" ? "pl-lg-5" : "pr-lg-5"
            )}
          >
            <div
              className={
                currentDirection === "rtl"
                  ? classes.reportSection
                  : classes.pl_30
              }
            >
              <h2>{t("Reports")}</h2>
              <ul
                className="list"
                style={{ textAlign: i18n.dir() === "rtl" ? "right" : "left" }}
              >
                <li>{t("Reports Key Point One")}</li>
                <li>{t("Reports Key Point Two")}</li>
                <li>{t("Reports Key Point Three")}</li>
                <li>{t("Reports Key Point Four")}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Content;
